.container {
  display: flex;
  gap: var(--Size-size-88, 88px);
  text-align: left;
  flex-direction: row;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.content {
  width: 100%;
  padding-bottom: 24px;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.textAreaContainer {
  display: flex;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.textAreaContent {
  flex: 1;
}

.textAreaContent :global(.textarea-sync-disabled) {
  width: 100%;
}


.textAreaContent p:global(.label) {
  text-transform: capitalize;
  margin-bottom: 8px;
}

.textAreaWrapper {
  display: flex;
  min-height: 200px;
}

.textAreaWrapper :global(.cm-line) {
  color: var(--Colors-Text-text-primary, #292929);
  padding-left: 0px;
}

.textAreaWrapper :global(.cm-placeholder) {
  color: var(--Colors-Neutral-gray-500, #9E9A86);
}

.textAreaWrapper :global(.cm-line),
.textAreaWrapper :global(.cm-placeholder) {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.infoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing--16, 16px);
}