.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
  height: 100%;
}

.container h3 {
  font-weight: 700;
}

.filtersAndPagination {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.filtersAndPagination .inputStyle {
  display: flex;
  width: 50%;
  justify-content: flex-start;
  align-items: center;

  width: 300px;
  border: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
  border-radius: 8px;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 100% */
}

.filtersAndPagination .inputStyle i {
  padding: 9px 0px 9px 12px;

  color: var(--Colors-Neutral-gray-700, #797466);
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  /* 100% */
}

.filtersAndPagination input {
  width: 100%;
  padding: 6px 10px;
  height: 18.41px;
}

.filtersAndPagination input::placeholder {
  color: var(--Colors-Neutral-gray-500, #918e7e);
  font-size: 14px;
  font-weight: 400;
}

.filtersAndPagination .paginationAndInvite {
  display: flex;
  width: fit-content;
  flex-direction: row;
  justify-content: flex-end;
  padding: 3px;
}

.filtersAndPagination .paginationAndInvite .inviteBtn {
  align-self: center;
  margin-left: 10px;
  padding: 8.2px 12px;
}

.inviteBtn i {
  color: inherit;
}

.teamMemberListContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.teamMemberListHeader {
  display: flex;
  border-bottom: 2px solid var(--Colors-Neutral-gray-200, #dcdccc);
  border-top: 1px solid var(--Colors-Neutral-gray-200, #dcdccc);
  align-items: center;
  height: 15.6px;
}

.teamMemberListBody {
  height: 100%;
}

.headerRow {
  padding-top: 3px;
  border-top: 1px solid var(--Colors-Neutral-gray-200, #dcdccc);
  border-bottom: 2px solid var(--Colors-Neutral-gray-200, #dcdccc) !important;
}

.teamMember {
  height: 48px;
  border-bottom: 1px solid var(--Colors-Neutral-gray-100, #edeee6);
}

.teamMember:not(.headerRow):hover {
  background: var(--Colors-Neutral-gray-50, #f7f7f3);
}

.teamMember.current {
  background: var(--Colors-Neutral-gray-50, #f7f7f3);
  border-bottom-color: var(--Colors-Neutral-gray-200, #dcdccc);
  border-top: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
}

.teamMember:hover :global(.btn.secondary), .teamMember.current :global(.btn.secondary) {
  border-color: var(--Colors-Neutral-gray-200, #dcdccc);
}

.teamMemberContent {
  display: flex;
  flex-direction: column;
}

.loaderContainer {
  width: 100%;
  height: calc(100vh - 80px);
}

.loaderContainer div:global(.loader) {
  width: 80px;
  height: 80px;
}

.teamMemberLine {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.teamMember:global(.collapsed) {
  padding: 8px 0;
}

.teamMember:global(.collapsed) .teamMemberContent {
  padding: 8px 16px;
  border-radius: var(--br-12, 12px);
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.teamMemberListHeaderContainer {
  display: flex;
  flex-direction: column;
}

.teamMemberListHeader {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.lineSection p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.teamMemberListHeader .lineSection {
  padding: 11px 16px;
}

.teamMemberListRow {
  border-bottom: 1px solid var(--Colors-Text-text-palest, #d1ccb3);
}

.teamMemberHeaderRow {
  border-top: 1px solid var(--Colors-Text-text-palest, #d1ccb3);
}

.teamMemberContent .lineSection {
  padding: 12px 16px;
}

.lineSection :global(.palest) {
  display: block;
  width: 100%;
}

.lineSection div {
  display: flex;
  align-items: center;
  gap: 6px;
}

.lineSection.nameColumn {
  flex: 0 0 calc(25% - 32px);
  gap: 8px;
}

.lineSection.emailColumn {
  flex: 0 0 calc(25% - 32px);
}

.lineSection.accountTypeColumn {
  flex: 0 0 calc(10% - 32px);
}

.lineSection.statusColumn {
  flex: 0 0 calc(10% - 32px);
}

.lineSection.tagsColumn {
  flex: 0 0 calc(15% - 32px);
  gap: 8px;
}

.lineSection.tagsColumn p {
  background-color: var(--Colors-Neutral-gray-100, #f7f7f3);
  border: 1px solid var(--Components-Badge-badge-light-border, #dcdccc);
}

.lineSection.actionColumn {
  flex: 0 0 calc(15% - 32px);
  display: flex;
  justify-content: flex-start;
}

.lineSection .imageContainer {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 4px;
  overflow: hidden;
}

.userImageContainer div:global(.loader) {
  border-width: 2px;
}

.status {
  padding: 5px 8px;
  border-radius: 8px;
  color: var(--Components-Badge-badge-light-text, #646059);
}

.activeStatus {
  background: var(--Components-Badge-badge-succss-color, #dcfcea);
  border: 1px solid var(--Components-Badge-badge-succss-border, #49df8e);
  color: var(--Colors-Green-800, #166534);
}

.absenceStatus,
.prohibitedStatus {
  border: 1px solid var(--Colors-Neutral-gray-200, #edeee6);
}

.teamMemberType {
  border: 1px solid var(--Colors-Neutral-gray-200, #edeee6);
}

.adminType,
.ownerType {
  border: 1px solid #7cddf0;
  color: var(--Colors-Cyan-800, #1c566e);
}

.tagsContainer :global(.see-all) {
  cursor: pointer;
}

.tagsContent :global(.hover-arrow) {
  width: 20px;
  height: 8px;
  fill: var(--Colors-Functional-Mono-white, #fff);
}

.tags {
  padding: 8px;
  border-radius: 8px;
  background: var(--Colors-Functional-white, #fff);
  box-shadow: 0px 0px 24px -4px rgba(17, 17, 20, 0.08),
    0px 8px 8px -4px rgba(17, 17, 20, 0.03);
}

.tags :global(p.tag) {
  padding: 6px 12px;
}

.tags p:hover {
  background: var(--Colors-Neutral-gray-50, #f7f7f3);
}

@media (max-width: 1280px) {
  .lineSection p {
    font-size: 14px;
  }

  .lineSection.actionColumn {
    justify-content: center;
  }

  .lineSection .actionColumn button {
    padding: 4px 8px;
  }
}