.container {
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: var(--br-16, 16px);
  background: var(--Colors-Neutral-gray-50, #f7f7f3);

  height: calc(100vh - 80px - 18px);
  overflow: auto;
}

.inner {
  width: 390px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-top: 20px;
}

.setupTextContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-width: 540px;
}

.newDeviceText {
  display: flex;
  gap: 8px;
}

.newDeviceText i {
  color: var(--Colors-Functional-danger, #d12d52);
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.actionContainer {
  width: 100%;
}

.continueButton {
  width: 95%;
}

.technicalSetupAction {
  color: var(--Components-Button-Link-button-link-text, #1a73e8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;
}

.technicalSetupAction:hover {
  cursor: pointer;
}

.loaderContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.loaderContent,
.videoContent {
  opacity: 1;
}

.loaderContent:global(.hidden),
.videoContent:global(.hidden) {
  opacity: 0;
  height: 0px;
}

.loaderActions {
  border-radius: 8px;
  border-radius: var(--br-8, 8px);
  display: flex;
  gap: 8px;
  height: 32px;
  justify-content: center;
  padding: 6px;
  position: absolute;
  top: 330px;
  left: calc(195px - 60px);
}

.loaderVideo {
  width: 390px;
  height: 390px;
}

@media (max-height: 850px) {
  .loaderActions {
    top: calc(240px - 44px - 16px);
  }

  .loaderVideo {
    max-height: 240px;
  }
}

@media (max-height: 650px) {
  .loaderActions {
    top: calc(240px - 44px - 48px);
  }

  .loaderVideo {
    max-height: 200px;
    top: 8px;
  }

  .actionsBtnLoader {
    height: 40px !important;
    bottom: 20px;
  }

  .videoContent {
    margin: 12px auto;
  }

  .heading p {
    font-size: 24px;
  }

  .setupTextContainer p,
  .setupTextContainer span,
  .technicalSetupAction {
    font-size: 14px;
    margin: 0 auto;
  }

  .text {
    font-size: 14px;
  }

  .inner,
  .setupTextContainer {
    display: flex;
    gap: 4px;
  }

  .newDeviceText i {
    font-size: 14px;
  }

  .actionContainer {
    padding: 0;
    margin-top: 10px;
    margin-bottom: 4px;
  }

  .actionContainer .continueButton {
    height: 10px;
    padding: 1px;
    padding-bottom: 27px;
  }

  .actionContainer .continueButton>div {
    padding: 10px 14px;
  }
}