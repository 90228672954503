.container {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
}

.container img {
  width: 24px;
  height: 24px;
}

.container div:global(.connection-message-container) {
  display: none;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 1;
  width: 200px;
  right: -100px;
  top: 40px;
  box-shadow: 0px 1px 3px 0px rgba(17, 17, 20, 0.08),
    0px 1px 2px 0px rgba(17, 17, 20, 0.06);
}

.showTooltip div:global(.connection-message-container),
.container:hover div:global(.connection-message-container) {
  display: flex;
}

.showTooltip div:global(.connection-message-container .arrow),
.container div:global(.connection-message-container .arrow) {
  position: absolute;
  width: 0;
  height: 0;
  top: -12px;
  left: 73px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #363636;
}

.container div:global(.message) {
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 5px;
  background: var(--Colors-Neutral-gray-900, #363636);

  color: var(--Colors-Text-text-white, #fff);
  text-align: center;

  /* Content/Small */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
}

.tooltipContainer {
  padding: 10px 16px;
  text-align: center;
}