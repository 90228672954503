.EditProfileModal {
  display: flex;
  width: 552px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: var(--br-16, 16px);
  background: var(--Colors-Functional-Mono-white, #FFF);
  box-shadow: 0px 8px 8px -4px rgba(17, 17, 20, 0.03);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.content div:global(.user-container) {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
}

.content div:global(.user-info-container) {
  width: 100%;
}

.imageContainer {
  width: 80px;
  height: 80px;
  border: 1px solid var(--Components-Button-Outline-button-outline-color, #DCDCCC);
  border-radius: 100px;
}

.imageContainer img:global(.image) {
  border-radius: 100px;
}

.imageContainer div:global(.image-loading) {
  width: 80px;
  height: 80px;
  display: "flex";
}

.imageContainer div:global(.image-error) {
  background: transparent;
  width: 100%;
  height: 100%;
}

.imageContainer div:global(.image-error) i {
  color: var(--Colors-Neutral-gray-400, #BEBEB3);
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px;
  width: 40px;
  height: 40px;
}

.content label:global(.upload-container) {
  display: flex;
  padding: 9px var(--spacing--12, 12px);
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  border: 1px solid var(--Components-Button-Outline-button-outline-color, #DCDCCC);
  background: var(--Colors-Main-transparent, rgba(255, 255, 255, 0.01));
  cursor: pointer;
}

.content label:global(.upload-container) input {
  display: none;
}

.content form:global(.form-container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.content form div:global(.form-row) {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.content form label:global(.label) {
  color: var(--Colors-Neutral-gray-700, #444340);
  /* Elements/Caption M */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.content form label:global(.label.disabled) {
  color: var(--Colors-Neutral-gray-400, #BEBEB3);
}

.actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.actions .save.loading {
  display: flex;
  gap: 5px;
}

.content form input:global(.FormControl) {
  border: 1px solid #BEBEB3;
  background: transparent;
  color: var(--Colors-Text-text-primary, #292929);
  /* Elements/Input M */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.content form input:global(.FormControl):disabled {
  background: var(--Colors-Neutral-gray-200, #EDEEE6);
  color: var(--Colors-Neutral-gray-400, #BEBEB3);
}
