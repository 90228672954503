.container {
  margin-top: 24px;
}

.tabsList {
  display: flex;
  border-bottom: 1px solid #BFBEB6;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.tabsTrigger {
  font-weight: 500;
  font-size: 22px;
  line-height: 18px;
  padding: 16px 24px;
  cursor: pointer;
  border-radius: 8px;
}

.tabsTrigger:hover {
  background-color: rgba(155, 155, 155, 0.1);
}

.tabsTrigger[data-state='active'] {
  border-bottom: 4px solid var(--Colors-Text-text-primary, #292929);
  padding-bottom: 12px;
  color: var(--Colors-Text-text-primary, #292929);
  font-weight: bold;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.descriptionContent {
  text-align: left;
  padding-top: 40px;
}

.descriptionContent h2 {
  line-height: 40px;
}

.descriptionContent h2 + p {
  margin-top: 24px;
  line-height: 24px;
}

.descriptionContent ul {
  margin-top: 16px;
}

.descriptionContent li {
  margin-bottom: 16px;
  display: inline-flex;
  align-items: center;
}

.descriptionContent li:last-child {
  margin-bottom: 0;
}

.descriptionContent ul li::before {
  font: var(--fa-font-solid);
  content: "\f00c";
  padding: 9px 10px;
  border-radius: 50%;
  background-color: #fecd20;
  font-size: 22px;
  margin-right: 16px;
  color: white;
}

.orElement {
  font-size: 18px;
  line-height: 24px;
}