.container {
  margin-top: 40px;
}

.title {
  color: #1F1F23;
  font-weight: 700;
  font-size: 40px;
  text-align: left;
  margin-bottom: 40px;
}