.activityContainer {
  padding: 16px;
  border: 1px solid var(--Colors-Neutral-gray-200, #dcdccc);
  border-radius: 12px;
  width: calc(100% - 34px);
}

.activityContainer :global(.main-container) {
  padding: 0;
  min-height: 400px;
}

.leftWrapper {
  flex: 1.2;
  text-align: left;
}

.rightWrapper {
  flex: 1;
}

.instructions {
  text-align: left;
  background-color: white;
  padding: 24px 16px;
  border-radius: 12px;
}

.instructionsTitle {
  display: flex;
  align-items: center;
  line-height: 24px;
  font-size: 22px;
  font-weight: 500;
}

.instructionsTitle::before {
  font: var(--fa-font-solid);
  content: "\e1d8";
  font-size: 24px;
  margin-right: 8px;
}

.referenceInstructionsContent {
  line-height: 22px;
  margin-bottom: 24px;
}

.mySituationContainer {
  background-color: var(--Colors-Neutral-gray-100, #edeee6);
  padding: 25px 24px;
  border-radius: 10px;
  margin-top: 16px;
}

.mySituationTitle {
  font-weight: 600;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
}

.profileImage {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 8px;
  background-color: gainsboro;
}

.mySituationText {
  line-height: 22px;
}
