.container > * {
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 32px - 16px - 2 * 16px);
  align-items: center;
  justify-content: center;
  gap: 40px;
  background: var(--Colors-Neutral-gray-100, #f7f7f3);

  border-radius: var(--br-16, 16px);
  overflow: auto;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.dialogFooter button:global() {
  border: none;
  cursor: pointer;
  min-width: 128px;
}

.dialogFooter button:global(.btn.dbl.success.submit) {
  background-color: var(--Colors-Functional-Info-600, #167284);
  color: white;
}

.dialogFooter button:global(.btn.dbl.success.submit .front) {
  background-color: var(--Colors-Functional-Info-600, #22b5d1);
}

.dialogFooter button:global(.btn.dbl.success.cancel) {
  background-color: white;
}

.dialogFooter button:global(.btn.dbl.success.cancel .front) {
  background-color: white;
  color: black;
}
