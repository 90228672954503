.sessionContainer {
  display: flex;
  gap: 16px;
  justify-content: center;
  height: calc(100vh - 80px);
}

.workshopInstanceContainer {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

.loaderContainer {
  width: 100%;
  height: calc(100vh - 80px);
}

.loaderContainer div:global(.loader) {
  width: 80px;
  height: 80px;
}
