.imgContainer {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  border-radius: 100%;
}

.imgContainer img {
  border-radius: 100%;
}

.imgContainer div:global(.image-loading) {
  width: 24px;
  height: 24px;
}

.imgContainer div:global(.loader) {
  border: solid 1px white;
  border-color: #000000 #000000 #000000 transparent;
}

.imgContainer div:global(.image-error) {
  font-size: 50px;
  color: white;
}

.fallbackIconClass {
  font-size: 12px;
}