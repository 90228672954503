.journeyDetailsContainer {
  height: calc(100vh - 80px);
  overflow: auto;
  overflow-x: hidden;
}

.descriptionText {
  margin-top: 24px;
  text-align: left;
  font-size: 18px;
  line-height: 24px;
}

.skeletonsContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.aggregatesSkeleton {
  margin-top: 12px;
  margin-bottom: -12px;
  display: flex;
  flex-direction: column;
}

.skeletonsContainer .bannerSkeleton {
  width: 100%;
}

.skeletonsContainer .descriptionSkeleton {
  margin-top: 24px;
}