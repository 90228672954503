.tagGrid {
  border: 1px solid #bebeb3;
  background: transparent;
  color: var(--Colors-Text-text-primary, #292929);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-radius: 10px;
  padding: 6px;
  min-height: 34px;
  position: relative;
}

.tagGridDisabled {
  border-color: var(--Colors-Neutral-gray-200, #dcdccc);
  background-color: var(--Colors-Neutral-gray-100, #edeee6);
}

.gridCell {
  width: 100%;
}

.tagRow {
  width: calc(100% - 32px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  min-height: 32px;
}

.tagRow i {
  display: flex;
  width: 12px;
  height: 12px;

  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: var(--Colors-Text-text-faded, #c6c5b2);
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
}

:is(.tag:hover, .tag[data-focus-visible]) i {
  cursor: pointer;
  color: #000;
}

.tag {
  display: flex;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;

  border-radius: var(--br-6, 6px);
  border: 1px solid var(--Components-Badge-badge-light-border, #dcdccc);
  background: var(--Components-Badge-badge-light-color, #f7f7f3);

  color: var(--Colors-Text-text-primary, #292929);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.comboBox {
  appearance: none;
  width: calc(100% - 12px);
  border-radius: 0.375rem;
  border-style: none;
  font-size: 1rem;
  line-height: 1.5rem;
  color: hsl(204 10% 10%);
  outline: none !important;
  height: 20px;
  padding: 6px;
}

.comboBox::placeholder {
  color: hsl(204 10% 10% / 0.6);
}

.comboBox[data-focus-visible] {
  outline-style: solid;
}

.popover {
  width: calc(100% + 14px);
  z-index: 9999;
  display: flex;
  max-height: min(var(--popover-available-height, 300px), 300px);
  flex-direction: column;
  overflow: auto;
  overscroll-behavior: contain;
  border-radius: 12px;
  border-width: 1px;
  border-style: solid;
  border-color: hsl(204 20% 88%);
  background-color: hsl(204 20% 100%);
  color: hsl(204 10% 10%);
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.popover::-webkit-scrollbar {
  width: 12px;
}

.popover::-webkit-scrollbar-track {
  border-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background-color: #f1f1f1;
}

.popover::-webkit-scrollbar-thumb {
  border-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  background: #c1c1c1;
}

.comboBoxItem {
  display: flex;
  cursor: default;
  scroll-margin: 6px;
  align-items: center;
  gap: 6px;
  border-radius: var(--br-12, 12px);
  padding: 6px;
  outline: none !important;
  margin: 6px;
}

.comboBoxItem:hover {
  background: var(--Colors-Functional-Success-100, #e8fcef);
  cursor: pointer;
}

.comboBoxItem[data-active-item] {
  background: var(--Colors-Functional-Success-100, #e8fcef);
}

.noResults {
  gap: 0.5rem;
  padding: 0.5rem;
}
