.dragAndDropSectionList {
  flex: 1;
  width: 100%;
}

.sectionTitle {
  display: flex;
  align-items: center;
  gap: 4px;
  text-align: left;
  padding-bottom: 16px;
}

.badge {
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--br-pill, 9999px);
  border: 1px solid var(--Components-Badge-badge-primary-border, #FECD20);
  background: var(--Components-Badge-badge-primary-color, #FEF8E1);
  color: var(--Components-Badge-badge-primary-text, #874B0C);
  text-align: center;
  font-weight: 500;
  line-height: 14px;
}

.sectionTitleText {
  display: inline;
}