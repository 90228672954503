.container {
  display: flex;
  gap: var(--br-24, 24px);
  text-align: left;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  padding-bottom: 25px;
}

.infoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing--16, 16px);
}

.infoContainerText {
  display: flex;
  flex-direction: column;
  padding: 24px 32px 28px 32px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--br-12, 12px);

  background: var(--Colors-Functional-white, #fff);
}

.viewResultsContainer {
  padding: 0px !important;
  background-color: transparent !important;
  gap: 32px !important;
}

.viewResultsContainer .infoContainer {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 24px;
  gap: 24px;
}

.viewResultsContainer .description {
  position: relative;
  font-size: 22px;
  padding-bottom: 24px;
}

.viewResultsContainer .description::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: #bebeb3;
}

.answersDataContainer,
.answersContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--Size-size-8, 8px);
  padding-left: 20px;
  margin-left: -20px;
  padding-right: 20px;
  margin-right: -20px;
  padding-bottom: 24px;
}

.answersDataContainer {
  gap: var(--Size-size-16, 16px);
}

.answersDataContainer span:global(.challenge-author) {
  text-decoration: underline;
  cursor: pointer;
}

.answersDataContainer .challengeAuthorText {
  margin-top: 3px;
}

.viewResultsContainer .answersDataContainer {
  height: auto;
  overflow: auto;
  margin-left: 0px;
  margin-right: 0px;
}

.answerExplanation {
  height: 0;
  overflow: hidden;
}

.answerExplanation:global(.expanded) {
  height: auto;
  padding-bottom: 1px;
}

.answerAuthorsChoice {
  border: 1.5px solid #fecd20;
  border-radius: 8px;
  background: #fef8e1;
  color: #874b0c;
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 12px;
  line-height: 12px;
  padding: 5px 8px;
  margin-left: 10px;
}

.answer {
  position: relative;
}

.textFieldChevron {
  top: -2px;
}

.leftWrapper {
  flex: 1.2;
}
