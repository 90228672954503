.container {
  overflow: auto;
  gap: 0;
}

.children {
  margin-bottom: -50px;
  width: 100%;
  flex: 1;
}

.arrowContainer {
  width: 23px;
  height: 50px;
  position: sticky;
  bottom: 5px;
  left: 95%;
}

.arrow {
  font-size: 24px;
  color: #767171;
  animation: pulse 1s infinite;
  z-index: 10;
}

.arrowHidden {
  visibility: hidden;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
