.overlay {
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.dialog {
  z-index: 999;
  position: fixed;
  padding: 16px 0px;
  inset: 0.5rem;
  margin: auto;
  width: 90vw;
  height: 100%;
  max-width: 450px;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  gap: 16px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  border-radius: var(--br-16, 16px);
  background: var(--Colors-Functional-Mono-white, #fff);
  box-shadow: 0px 8px 8px -4px rgba(17, 17, 20, 0.03);
}

.dialog>div {
  height: auto;
}

.dialog:focus {
  outline: none;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 18px 0px 24px;
}

.heading {
  font-size: 22px;
}

.dismiss:hover {
  cursor: pointer;
}

.dismiss i {
  padding: 6px;
  display: flex;
  width: 20px;
  height: 20px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: var(--Colors-Neutral-gray-700, #797466);
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.dismiss:hover i {
  background-color: var(--Colors-Neutral-gray-700, #444340);
  color: var(--Colors-Functional-Mono-white, #fff);
  border-radius: 50%;
}

.content {
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(80vh - 62px - 72px - 32px);
  padding: 0px 24px;
}

.error {
  padding: 0px 24px;
  color: #941D3F;
  min-height: 20px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 24px;
}


@media (min-width: 640px) {
  .dialog {
    top: 10vh;
    bottom: 10vh;
    margin-top: 0px;
    max-height: 80vh;
    height: max-content;
    width: 420px;
  }

  .header {
    height: 100%;
  }

  .content {
    height: 100%;
  }

  .footer {
    height: 100%;
  }
}