.dialogContent {
  position: absolute;
  min-width: 568px;
  transform-origin: bottom left;
  animation: swingIn 0.3s ease-out forwards;
}

.dialogContent .badge {
  width: 120px;
}

.dialogContent:global(.waiting) {
  background: var(--Colors-Functional-Warning-100, #fef8e1);
}

.dialogContent:global(.waiting) :global(.big-icon) {
  background: var(--Colors-Functional-Warning-300, #fce399);
  color: var(--Colors-Functional-Warning-500, #ca8a04);
}

.dialogContent:global(.waiting) :global(.accent) {
  color: var(--Colors-Functional-Warning-500, #ca8a04);
}

.dialogContent:global(.ready) {
  background: var(--Colors-Functional-Success-100, #e8fcef);
}

.dialogContent:global(.ready) :global(.big-icon) {
  background: var(--Colors-Functional-Success-200, #a5f4c8);
  color: var(--Colors-Functional-Success-400, #4aac77);
}

.dialogContent:global(.ready) :global(.accent) {
  color: var(--Colors-Functional-Success-400, #4aac77);
}

.dialogContent :global(.accent.green) {
  color: var(--Colors-Functional-Success-400, #4aac77);
}

.dialogContent:global(.error) {
  background: var(--Colors-Functional-Danger-200, #fef2f2);
}

.dialogContent:global(.error) :global(.big-icon) {
  background: var(--Colors-Functional-Danger-300, #f6d6d6);
  color: white;
}

.dialogContent:global(.error) :global(.error) {
  color: var(---Colors-Functional-Danger-700, #b91c1c);
}

.content {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: 0.3s all;
}

.content :global(.big-icon) {
  font-family: "Pally Variable";
  font-size: 42px;
}

.content .textContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.content .textContainer .badge {
  height: 14px;
  padding: 3px 0px;
  border-radius: var(--br-pill, 9999px);
}

.content .textContainer h3 {
  font-weight: 500;
}

@keyframes swingIn {
  0% {
    transform: scale(1.5) translateY(300px) translateX(-450px);
  }
  100% {
    transform: scale(1) 0;
  }
}
