.container {
  flex: 0;
  padding: 21px 24px;
  cursor: default;
}

.container:global(.blue-background) {
  background: var(--Colors-Cyan-50, #EDFCFE);
}

.container:global(.waiting), .container:global(.observer) {
  background: var(--Colors-Functional-Warning-100, #FEF8E1);
}

.container:global(.waiting) :global(.big-icon),
.container:global(.observer) :global(.big-icon) {
  background: var(--Colors-Functional-Warning-300, #FCE399);
  color: var(--Colors-Functional-Warning-500, #CA8A04);
}

.container:global(.waiting) :global(.accent) {
  color: var(--Colors-Functional-Warning-500, #CA8A04);
}

.container:global(.ready) {
  background: var(--Colors-Functional-Success-100, #E8FCEF);
}

.container:global(.ready) :global(.big-icon) {
  background: var(--Colors-Functional-Success-200, #A5F4C8);
  color: var(--Colors-Functional-Success-400, #4AAC77);
}

.container:global(.ready) :global(.accent) {
  color: var(--Colors-Functional-Success-400, #4AAC77);
}

.container :global(.accent.green) {
  color: var(--Colors-Functional-Success-400, #4AAC77);
}

.container:global(.error) {
  background: var(--Colors-Functional-Danger-200, #FEF2F2);
}

.container:global(.error) :global(.big-icon) {
  background: transparent;
  color: white;
  position: relative;
}

.container:global(.error) :global(.big-icon)::before {
  content: '';
  display: block;
  position: absolute;
  top: -4px;
  --r: 5px;
  width: 62px;
  height: 60px;
  aspect-ratio: 1 / cos(30deg);
  --_g: calc(tan(60deg)*var(--r)) bottom var(--r), #000 98%, #0000 101%;
  -webkit-mask: conic-gradient(from -30deg at 50% calc(200% - 3* var(--r) / 2), #000 60deg, #0000 0) 0 100% / 100% calc(100% - 3* var(--r) / 2) no-repeat, radial-gradient(var(--r) at 50% calc(2* var(--r)), #000 98%, #0000 101%), radial-gradient(var(--r) at left var(--_g)), radial-gradient(var(--r) at right var(--_g));
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  transition: --r .6s;
  cursor: pointer;
  z-index: 0;
  background: var(--Colors-Functional-Danger-300, #F6D6D6);
}

.container:global(.error) :global(.error) {
  color: var(---Colors-Functional-Danger-700, #B91C1C);
}

.actionsInfo {
  display: flex;
  align-items: center;
  gap: var(--br-2xl, 16px);
  text-align: left;
}

.actionsInfo :global(.observer) {
  width: 26.219px;
  height: 20.41px;
}

.actionButton {
  margin-top: 6px;
}

.actionsInfo>h3 {
  text-align: left;
}

.actionsInfo>h3> :global(.accent) {
  color: var(--Colors-Functional-Info-600, #22B5D1);
}

.actionsInfo>h3> :global(.accent.success) {
  color: var(--Colors-Functional-success-dark, #15A457);
}

.actionsInfo div:global(.big-icon) {
  overflow: hidden;
}

.actionsInfo div:global(.big-icon i) {
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.actionsInfo div:global(.notice),
.actionsInfo div:global(.ready),
.actionsInfo div:global(.waiting),
.actionsInfo div:global(.error) {
  animation-duration: 500ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  scale: 1;
}

.actionsInfo div:global(.notice) {
  animation-name: iconNotice;
}

.actionsInfo div:global(.waiting) {
  animation-name: iconWaiting;
}

.actionsInfo div:global(.ready) {
  animation-name: iconReady;
}

.actionsInfo div:global(.error) {
  animation-name: iconError;
}

.iconAnimation {
  z-index: 1;
  height: 100%;
}

@keyframes iconNotice {
  from {
    scale: 1.2;
  }

  to {
    scale: 1;
  }
}

@keyframes iconReady {
  from {
    scale: 1.2;
  }

  to {
    scale: 1;
  }
}

@keyframes iconWaiting {
  from {
    scale: 1.2;
  }

  to {
    scale: 1;
  }
}

@keyframes iconError {
  from {
    scale: 1.2;
  }

  to {
    scale: 1;
  }
}