.container {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px;
  text-align: left;
  border-left: 2px solid;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.container:global(.solo-work) {
  border-left-color: #fecd20;
  background-color: #fef8e1;
}

.container:global(.group-work) {
  border-left-color: #1eaed0;
  background-color: #edfcfe;
}

.topLine {
  display: flex;
  flex-wrap: wrap;
  gap: 6px 8px;
  align-items: center;
}

.instructionsLabel {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.instructions {
  line-height: 22px;
  font-size: 16px;
  margin-top: 6px;
}
