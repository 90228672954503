.imageContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.imageContainer div:global(.image-loading.hidden),
.imageContainer img:global(.image.hidden) {
  opacity: 0;
}

.imageContainer img {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.imageContainer div:global(.image-loading) {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.imageContainer div:global(.image-error) {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageContainer div:global(.image-abbreviation) {
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  align-items: center;
  border-radius: var(--br-4, 4px);
  background: var(--Colors-Neutral-gray-450, #A3A294);
  color: var(--Colors-Functional-Mono-white, #FFF);
}