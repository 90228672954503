.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.content div:global(.user-container) {
  display: flex;
  gap: 16px;
  align-items: center;
}

.content div:global(.user-container) button {
  display: flex;
  height: 20px;
  padding: 9px var(--spacing--12, 12px);
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  background: var(--Components-Button-Secondary-button-secondary-color, #F7F7F3);
  cursor: pointer;
}

.content div:global(.user-info-container) {
  width: 100%;
}

.content div:global(.user-image-container) {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background: var(--Colors-Neutral-gray-200, #edeee6);
  padding: 20px;
}

.content div:global(.user-image-container) img {
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.content div:global(.user-image-container) i {
  color: var(--Colors-Neutral-gray-400, #bebeb3);
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px;
}

.content label:global(.upload-container) {
  display: flex;
  padding: 9px var(--spacing--12, 12px);
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: var(--br-6, 6px);
  border: 1px solid var(--Components-Button-Outline-button-outline-color, #C6C5B2);
  background: var(--Colors-Main-transparent, rgba(255, 255, 255, 0.01));
  cursor: pointer;
}

.content span:global(.button-text) {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  color: #646059;
}

.content label:global(.upload-container) input {
  display: none;
}

.imageContainer {
  width: 80px;
  height: 80px;
  border: 1px solid var(--Components-Button-Outline-button-outline-color, #dcdccc);
  border-radius: 100px;
}

.imageContainer img:global(.image) {
  border-radius: 100px;
}

.imageContainer div:global(.image-loading) {
  width: 80px;
  height: 80px;
  display: "flex";
}

.imageContainer div:global(.image-error) {
  background: transparent;
  width: 100%;
  height: 100%;
}

.imageContainer div:global(.image-error) i {
  color: var(--Colors-Neutral-gray-400, #bebeb3);
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 40px;
  width: 40px;
  height: 40px;
}

.content label:global(.upload-container) {
  display: flex;
  height: 20px;
  padding: 9px var(--spacing--12, 12px);
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  border: 1px solid var(--Components-Button-Outline-button-outline-color, #DCDCCC);
  background: var(--Colors-Main-transparent, rgba(255, 255, 255, 0.01));
  cursor: pointer;
}

.content label:global(.upload-container) input {
  display: none;
}

.content form:global(.form-container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.content form div:global(.form-row) {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.content form label:global(.label) {
  color: var(--Colors-Neutral-gray-700, #444340);
  /* Elements/Caption M */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.content form label:global(.label.disabled) {
  color: var(--Colors-Neutral-gray-400, #bebeb3);
}

.content form div:global(.form-row.actions) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content form input:global(.FormControl),
.content form .selectInput {
  border: 1px solid #bebeb3;
  background: transparent;
  color: var(--Colors-Text-text-primary, #292929);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-radius: 10px;
}

.content form input:global(.FormControl) {
  height: 32px;
  width: auto;
}

.content form .selectInput {
  height: 22px;
}

.content form .selectInput:disabled {
  background: var(--Colors-Neutral-gray-200, #edeee6);
  color: var(--Colors-Neutral-gray-400, #bebeb3);
}

.content form input:global(.FormControl):disabled {
  background: var(--Colors-Neutral-gray-200, #edeee6);
  color: var(--Colors-Neutral-gray-400, #bebeb3);
}

.errorContainer {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 30px;
  color: var(--Colors-Functional-Error-600, #ff6b6b);
  font-weight: 500;
}