.congratulationsContainer {
  width: 100%;
  height: 258px;
  position: relative;
}

.congratulationConfetti {
  position: absolute;
  width: 100%;
  height: 258px;
  overflow: hidden;
}

.congratulationsContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18.9px 48px;
  border-radius: var(--br-12, 12px);
  border: 1px solid var(--Colors-Functional-Success-600, #64ECA2);
  background: var(--Colors-Functional-Success-100, #E8FCEF);
  overflow-x: auto;
}

.congratulationsContent div:global(.info-container) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing--24, 24px);
  flex: 1 0 0;
}

.congratulationsContent h1 {
  color: var(--Colors-Text-text-primary, #292929);
  font-family: "Pally Variable";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.congratulationsContent div:global(.text-content) p {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 12px var(--spacing--12, 12px);
  align-self: stretch;
  flex-wrap: wrap;
  color: var(--Colors-Text-text-primary, #292929);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.congratulationsContent div:global(.text-content span.successfully) {
  display: flex;
  transform: rotate(-13.832deg);
  padding: 4px var(--spacing--12, 12px);
  font-weight: bold;

  align-items: flex-start;
  gap: 10px;
  border-radius: var(--spacing--sm, 8px);
  background: var(--Colors-Brand-brand-primary, #F6BE30);
}

.congratulationsContent div:global(.image-container) {
  height: 218px;
}

.congratulationsImage {
  width: 100%;
}

@media (max-width: 1455px) {
  .congratulationsContent {
    padding: 18.9px;
  }
}

@media (max-width: 1390px) {
  .congratulationsContent>* {
    transform: scale(0.85);
  }
}