.timePickerWrapper {
  border: 1px solid #c6c5b2;
  border-radius: 12px;
  width: 100%;
  box-sizing: border-box;
  padding: 13px 16px !important;
}

.calendarIconClassName {
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #797466;
  cursor: pointer;
}

.timePickerWrapper:hover {
  border-color: #b6b6aa;
}

.calendarIconClassName:hover {
  color: #5f5b4f;
}

.calendarIconClassName:hover + .timePickerWrapper {
  border-color: #b6b6aa;
}
