.content form:global(.form-container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.content form div:global(.form-row) {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.content form label:global(.label) {
  color: var(--Colors-Neutral-gray-700, #444340);
  /* Elements/Caption M */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  margin-bottom: 3px;
}

.content form label:global(.label.disabled) {
  color: var(--Colors-Neutral-gray-400, #bebeb3);
}

.content form div:global(.form-row.actions) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content form input:global(.FormControl) {
  border: 1px solid #bebeb3;
  background: transparent;
  color: var(--Colors-Text-text-primary, #292929);
  /* Elements/Input M */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-radius: 10px;
  height: 32px;
}

.content form .selectInput {
  border: 1px solid #bebeb3;
  background: transparent;
  color: var(--Colors-Text-text-primary, #292929);
  /* Elements/Input M */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-radius: 10px;
  height: 22px;
}

.content .errorContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  color: var(--Colors-Functional-Error-600, #ff6b6b);
  font-weight: 500;
}

.content form .selectInput:disabled {
  background: var(--Colors-Neutral-gray-200, #edeee6);
  color: var(--Colors-Neutral-gray-400, #bebeb3);
}

.content form input:global(.FormControl):disabled {
  background: var(--Colors-Neutral-gray-200, #edeee6);
  color: var(--Colors-Neutral-gray-400, #bebeb3);
}

.errorContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 30px;
  color: var(--Colors-Functional-Error-600, #ff6b6b);
  font-weight: 500;
}

.bulkConfirmationDialog {
  max-width: max-content;
  width: max-content;
}

.bulkConfirmationDialog h3 {
  margin-bottom: 12px;
}

.bulkConfirmationDialog td, .bulkConfirmationDialog th {
  padding: 6px;
  text-align: left;
}

.bulkConfirmationDialog td {
  border-bottom: 1px solid var(--Colors-Neutral-gray-100, #edeee6)
}