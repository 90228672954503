.footer {
  display: flex;
  gap: 10px;
}

.line {
  display: flex;
  align-items: center;
  gap: 16px;
}

.line+.line {
  margin-top: 24px;
}

.selectedProfileList {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.selectedProfile {
  display: flex;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--br-8, 8px);
  border: 1px solid var(--Colors-Neutral-gray-400, #bebeb3);
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.selectedProfile button {
  cursor: pointer;
}

.profileContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
}

.selector {
  width: calc(100% - 24px) !important;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--br-8, 8px);
  align-self: stretch;
}

.footer i:global(.faded) {
  font-size: 20px;
  cursor: pointer;
}

.footer a:global(.btn) {
  color: var(--Components-Button-Primary-button-primary-text, #292929);
}
