.container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.activityContainer > :global(.activity-container) {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
  border-bottom-left-radius: var(--br-xl, 12px);
  border-bottom-right-radius: var(--br-xl, 12px);
}

.container .activityContainer>div:global(.activity-container) {
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: var(--spacing--lg, 16px);
}

.container .activityContainer>div:global(.activity-container)> :global(.main-container:not(.footer)) {
  position: relative;
  flex: 1;
  overflow-y: auto;
}

.activityContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 1 auto;
  overflow: hidden;
}

.activityContainer:global(.observer-mode .activity-container) {
  cursor: url("/public/icons/cursor-not-allowed.svg"), auto;
}

.activityContainer:global(.observer-mode .main-container:not(.footer) > *) {
  pointer-events: none;
  user-select: none;
}