.workshopResult {
  width: 290px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-bottom: 20px;
}

.header {
  display: flex;
  padding: 8px;
  background: var(--Colors-Neutral-gray-200, #edeee6);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.header>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.progressBar {
  position: relative;
  top: 0;
  height: 4px;
  margin: 8px 0;
  width: 100%;
}

.workshopCompleted .progressBar {
  height: 1px;
  background: var(--Colors-Neutral-gray-200, #dcdccc);
}

.workshopCompleted .status {
  color: var(--Components-Badge-badge-success-text, #15663c);
  border: 1px solid var(--Components-Badge-badge-succss-border, #49df8e);
  background: var(--Components-Badge-badge-succss-color, #dcfcea);
}

.workshopOngoing .status {
  color: var(--Components-Badge-badge-info-text, #1c566e);
  border: 1px solid var(--Components-Badge-badge-info-border, #7cddf0);
  background: var(--Components-Badge-badge-info-color, #d2f5fb);
}

.teamName,
.level,
.activePlayersText {
  color: var(--Colors-Text-text-primary, #292929);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.level i {
  margin-left: 4px;
}

.tooltipContainer,
.progressTooltipContainer {
  width: 200px;
  height: auto;
  text-align: center;
}

.progressTooltipContainer {
  position: relative;
  bottom: 0;
}

.time {
  display: flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--br-8, 8px);
  background: var(--Components-Badge-badge-light-color, #f7f7f3);
  border: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
  color: var(--Colors-Neutral-gray-700, #444340);
  text-align: center;
}

.workshopCompleted .time {
  border: 1px solid var(--Components-Badge-badge-success-border, #49df8e);
  background: var(--Components-Badge-badge-success-color, #dcfcea);
}

.workshopResult .progressData {
  margin-bottom: 10px;
}

.status {
  display: flex;
  padding: 6px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  border-radius: var(--br-8, 8px);

  color: var(--Colors-Functional-Mono-Black, #000);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
}

.content {
  display: flex;
  height: 100%;
  padding: 8px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 0px 0px var(--br-8, 8px) var(--br-8, 8px);
  border: 2px solid var(--Colors-Neutral-gray-200, #edeee6);
}

.activePlayersContent {
  display: flex;
  padding-top: 8px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
}

.actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.spacer {
  height: 1px;
  align-self: stretch;
  border-radius: 2px;
  background: var(--Colors-Neutral-gray-300, #dcdccc);
  margin: 10px 0;
}

.copyToClipboardButton:global(.small) {
  width: 100%;
  box-sizing: border-box;
  height: auto;
}
