.container {
  display: inline-block;
  padding: 6px 12px;
  border: 1px solid var(--Components-Badge-badge-light-border, #dcdccc);
  border-radius: 6px;
  background-color: var(--Components-Badge-badge-light-color, #f7f7f3);
  font-size: 14px;
  line-height: 20px;
}

.closeIcon {
  font-size: 12px;
  color: var(--Colors-Text-text-faded, #c6c5b2);
  margin-left: 6px;
  padding: 3px;
  cursor: pointer;
}
