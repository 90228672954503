.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing--lg, 16px);
  height: 100%;
}

.contentWrapper {
  overflow: auto;
}

.content {
  width: 100%;
  flex: 1 1;
  padding-bottom: 24px;
}

.content:global(.moment-content) {
  height: 100%;
}

.momentContainer {
  display: flex;
  align-items: flex-start;
  gap: var(--Size-size-32, 32px);
  align-self: stretch;
  flex-direction: column;
  height: 100%;
  padding-bottom: 24px;
}

.momentContainer:global(.group) {
  align-items: stretch;
}

.momentContainer :global(h3) {
  width: 100%;
  position: relative;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
}

.momentContainer :global(h3)::after {
  content: "";
  position: absolute;
  border-radius: 1px;
  background: var(--Colors-Neutral-gray-200, #dcdccc);
  height: 2px;
  width: 100%;
  left: 0;
  bottom: -12px;
}

.momentContainer:global(.group p.subtitle),
.momentContainer h3 {
  text-align: left;
}

.individualContent {
  display: flex;
  align-items: flex-start;
  gap: 23.5px;
  align-self: stretch;
  height: 100%;
}

.shareMomentContainer {
  flex: 1;
}

.line {
  width: 1px;
  height: 100%;
  background: var(--Colors-Neutral-gray-200, #dcdccc);
}

.textAreaContainer {
  position: relative;
  display: flex;
  min-height: 251px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.shareMomentContainer textarea {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
  height: 132px;
  outline: none;
  border-radius: var(--br-12, 12px);
  border: 1px solid var(--Colors-Neutral-gray-300, #c6c5b2);
  background: var(--Colors-Functional-white, #fff);
}

.momentContainer :global(.drag-and-drop-list) {
  padding-top: 0px !important;
}

/* TODO: Extract styles and textarea with button into a new component */
.actionContainer {
  position: absolute;
  right: 6px;
  bottom: 8px;
}

.actionContainer button {
  padding: 9px var(--spacing--12, 10px) !important;
  border-radius: var(--br-6, 6px);
  width: 12px;
  overflow: hidden;
  transition: 0.3s;
  border: none;
  justify-content: flex-end !important;
}

.actionContainer :global(.button-text) {
  display: none;
}

.actionContainer button i {
  width: 12px;
  height: 12px;
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
}

.actionContainer button:hover :global(.button-text) {
  display: block;
}

.actionContainer button:hover {
  width: 76px;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  gap: 6px !important;
}

.actionContainer button:disabled {
  opacity: 1 !important;
  background: var(
    --Components-Button-Utility-button-disabled-color,
    #edeee6
  ) !important;
  border-color: var(
    --Components-Button-Utility-button-disabled-color,
    #edeee6
  ) !important;
}

.actionContainer button:disabled :global(.button-text) {
  color: var(--Components-Button-Utility-button-disabled-text, #c6c5b2);
}

.actionContainer button:disabled i {
  color: var(--Components-Button-Utility-button-disabled-text, #c6c5b2);
}
