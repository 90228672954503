.resultsContainer {
  display: flex;
  flex-direction: column;
  gap: 23px;
  background-color: transparent;
  padding: 0 !important;

  max-height: calc(100vh - 80px);
  overflow: auto;
  border-radius: 0px;
}

.resultsContent {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: flex-start;
}
.resultsDataContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.content::-webkit-scrollbar {
  display: none;
}

.content:global(> div) {
  scroll-snap-align: start;
  flex-shrink: 0;
  position: relative;
}

.content :global(.video-player-container) > video {
  position: relative;
  flex: 1;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  left: auto;
  top: auto;
  transform: none;

  object-fit: contain;
  overflow-clip-margin: content-box;
  overflow: clip;
}
