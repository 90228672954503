.container {
  display: flex;
}

.inputContainer {
  flex: 1;
}

.leftWrapper {
  flex: 1.2;
}

.instructions {
  text-align: left;
  margin-bottom: 16px;
  font-weight: 600;
}

.textArea {
  outline: none;
  border: 1px solid var(--Colors-Text-text-palest, #dcdccc);
}

.textArea:focus {
  border-radius: var(--br-12, 12px);
  box-shadow: 0px 0px 0px 3px #fff, 0px 0px 0px 6px rgba(34, 181, 209, 0.25);
}

.editorWrapper {
  display: flex;
  min-height: 200px;
  text-align: left;
}

.editorWrapper :global(.cm-line) {
  color: var(--Colors-Text-text-primary, #292929);
}

.editorWrapper :global(.cm-placeholder) {
  color: var(--Colors-Neutral-gray-500, #9e9a86);
}

.editorWrapper :global(.cm-content) {
  min-height: 200px;
}

.editorWrapper :global(.cm-line) {
  padding: 0px;
}

.editorWrapper :global(.cm-line),
.editorWrapper :global(.cm-placeholder),
.editorWrapper :global(.input-sync-disabled) {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.referenceValueContainer {
  margin-top: 32px;
  text-align: left;
}

.referenceValueLabel {
  margin-bottom: 16px;
}

.referenceInstructions {
  margin-top: 16px;
  margin-bottom: 24px;
  text-align: left;
}

.referenceTitle {
  text-align: left;
}

.referenceAnswer {
  background-color: var(--Colors-Neutral-gray-100, #edeee6);
  padding: 16px 24px;
  border-radius: 10px;
  margin-top: 16px;
}

.referenceAnswerTitle {
  font-weight: 600;
  margin-bottom: 8px;
  text-align: left;
}

.groupAlignmentValue {
  text-align: left;
}
