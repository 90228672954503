.answer {
  box-sizing: border-box;
  display: flex;
  padding: var(--Size-size-15, 14.5px) 8px var(--br-15, 14.5px) 20px;
  border-radius: 10px;
  border: 1.5px solid var(--Colors-Neutral-gray-400, #AFAC95);
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  color: var(--Colors-Text-text-primary, #292929);
  font-weight: 600;
  position: relative;
  background-color: #fff;

  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  gap: var(--spacing--8, 8px);
}

.answer:global(.horizontal) {
  align-items: center;
  padding: var(--Size-size-15, 14.5px) 2px var(--br-15, 14.5px) 2px;
  position: relative;
  text-align: center;
}

.answer:global(.horizontal) .answerTopLine {
  justify-content: center;
}

.answer:global(.horizontal) .answerIcon {
  margin-left: 6px;
}

.answer:hover {
  border: 1.5px solid var(--Colors-Neutral-gray-600, #918C77);
  transition: 0.3s;
}

.answer:global(.selected) {
  border: 1.5px dashed var(--Colors-Functional-Info, #1EAED0);
  background-color: var(--Colors-Functional-info-light, #EDFCFE);
}

.answer.otherSelected {
  border: 1.5px dashed var(--Colors-Neutral-gray-700, #797466);
}

.answer.groupSelected {
  border: 1.5px dashed var(--Colors-Functional-Info, #1EAED0);
  background-color: var(--Colors-Functional-info-light, #EDFCFE);
}

.answerText {
  min-height: 24px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.answerIcon {
  margin-left: 8px;
}

.answer:global(.review) {
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
}

.answer:global(.review.expanded) {
  gap: var(--Size-size-4, 4px);
}

.answer:global(.disabled) {
  cursor: not-allowed;
  border: 1.5px solid var(--Colors-Functional-Info, #1EAED0);
  background-color: var(--Colors-Functional-info-light, #EDFCFE);
}

.answerTopLine {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.answerTopLineData {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  max-width: 85%;
}

.loading {
  cursor: progress;
}

.textContent {
  word-break: break-word;
}

.textContent p {
  font-size: 15px;
  line-height: 20px;

}

.answerChevron {
  position: static;
}