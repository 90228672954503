.transcriptsContainer {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: var(--Size-size-12, 12px);
  margin-bottom: -66px;
}

.transcript {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
}

.transcript .heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.transcript .content {
  font-size: 14px;
  gap: 0;
}

.transcript .content p {
  margin: 12px 0px;
}

.sidePanelContainer:global(.side-panel-final-screen) .transcriptsContainer {
  height: calc(100vh - 263px - 24px - 24px);
}

.noTranscripts {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: var(--Colors-Neutral-gray-800, #646059);
}
