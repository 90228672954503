.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdccc;
  border-radius: 12px;
  margin-bottom: 8px;
  padding-bottom: 0;
}

.containerChecked {
  border-color: var(--Colors-Functional-Success-400, #4aac77);
}

.containerExpanded {
  padding-bottom: 20px;
}

.collapsibleTrigger {
  cursor: pointer;
  padding: 16px 20px;
}

.collapsibleContent {
  overflow: hidden;
  padding: 0 16px;
}

.collapsibleContentInner {
  display: flex;
  flex-direction: column;
}

.collapsibleContent[data-state="open"] {
  animation: slideDown 300ms ease-out;
}

.collapsibleContent[data-state="closed"] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.container:last-child {
  margin-bottom: 0;
}

.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
}

.radioItem {
  display: flex;
  font-weight: 600;
  cursor: pointer;
}

.checkboxRoot {
  width: 18px;
  height: 18px;
  background-color: white;
  border: 1px solid #797466;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.checkboxRoot[data-state="checked"] {
  border-color: var(--Colors-Functional-success, #64eca2);
}

.checkboxIndicator {
  background-color: var(--Colors-Functional-success, #64eca2);
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.checkboxRoot[data-state="unchecked"] .checkboxIndicator {
  display: none;
}

.checkIcon {
  color: white;
  font-size: 12px;
}

.inputLabel {
  margin: 3px 0 6px;
  display: inline-block;
  font-weight: 600;
}

.input {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 13.5px 16px;
}

.timeError {
  color: #b02044;
  margin-top: 4px;
}

.slotsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 12px;
}

.participantsLabel {
  font-weight: 600;
}
