.bannerTop {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 36px);
  position: absolute;
  top: 18px;
  left: 18px;
}

.bannerTopLeft {
  display: flex;
  gap: 16px;
}

.ahaPlayLogo {
  position: absolute;
  top: 0;
  right: 0;
  width: 108px;
}

.banner {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  max-height: 600px;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.5));
}

.bannerImage {
  width: 100%;
  display: block;
}

.label {
  padding: 7px 14px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}

.type {
  color: white;
  background-color: #22B5D1;
}

.tag {
  color: #1F1F23;
  background-color: #FFECB9;
}

.bannerBottom {
  position: absolute;
  bottom: 18px;
  left: 18px;
  display: flex;
  gap: 12px;
}

.ahaBubbleContainer {
  padding: 23px 22px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.ahaBubble {
  font-size: 25px;
  color: black;
}

.ahaAuthor {
  align-self: end;
  display: flex;
  flex-direction: column;
  text-align: left;
  line-height: 18px;
  color: white;
}
