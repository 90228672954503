
.voiceIndicatorContainer {
  width: 20px;
  height: 12px;
  display: flex;
  align-items: center;
  gap: 0.5px;
  justify-content: center;
}

.voiceIndicatorContainer div:global(.indicator) {
  background-color: #22B5D1;
  opacity: 0.7;
  width: 2.5px;
  animation-duration: 350ms;
  animation-play-state: running;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border-radius: 8px;
}

.voiceIndicatorContainer div:global(.indicator:nth-child(1)) {
  animation-name: danceHeight1;
  animation-delay: -900ms;
  height: 5.85px;
}

.voiceIndicatorContainer div:global(.indicator:nth-child(2)) {
  animation-name: danceHeight2;
  animation-delay: -1500ms;
  height: 9.5px;
}

.voiceIndicatorContainer div:global(.indicator:nth-child(3)) {
  animation-name: danceHeight3;
  animation-delay: -600ms;
  height: 4.25px;
}

.voiceIndicatorContainer div:global(.indicator:nth-child(4)) {
  animation-name: danceHeight4;
  animation-delay: -1700ms;
  height: 11.12px;
}

.voiceIndicatorContainer div:global(.indicator:nth-child(5)) {
  animation-name: danceHeight5;
  animation-delay: -600ms;
  height: 4.25px;
}


@keyframes danceHeight1 {
  from {
    height: 20%;
  }

  to {
    height: 55%;
  }
}

@keyframes danceHeight2 {
  from {
    height: 20%;
  }

  to {
    height: 80%;
  }
}

@keyframes danceHeight3 {
  from {
    height: 10%;
  }

  to {
    height: 45%;
  }
}

@keyframes danceHeight4 {
  from {
    height: 35%;
  }

  to {
    height: 90%;
  }
}

@keyframes danceHeight5 {
  from {
    height: 10%;
  }

  to {
    height: 40%;
  }
}