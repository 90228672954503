.customDefinitionAnswer {
  padding: var(--Size-size-15, 14.5px) 20px var(--br-15, 14.5px) 20px;
}

.customDefinitionAnswer :global(.answer-text-content) {
  width: 100%;
}

.customDefinitionAnswer :global(.toggle-chevron-container) {
  position: absolute;
  right: 8px;
}

.textareaWrapper {
  display: flex;
  min-height: 208px;
}