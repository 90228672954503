.container {
  line-height: 20px;
  padding: 3px 8px;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: 600;
}

.primary {
  color: var(--Components-Badge-badge-info-text, #1c566e);
  border: 1px solid var(--Components-Badge-badge-info-border, #7cddf0);
  background-color: var(--Components-Badge-badge-info-color, #d2f5fb);
}

.secondary {
  color: var(--Components-Badge-badge-primary-text, #874b0c);
  border: 1px solid var(--Components-Badge-badge-primary-border, #fecd20);
  background-color: var(--Components-Badge-badge-primary-color, #fef8e1);
}
