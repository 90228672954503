.container {
  margin-top: 40px;
}

.title {
  font-weight: 600;
  line-height: 24px;
  font-size: 18px;
  text-align: left;
}

.goals {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  background-color: #26262B;
  color: #F7F7F3;
  border-radius: 12px;
  padding: 19px 0 21px;
}

.container :global(.journey-goal) {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.container :global(.journey-goal):last-child {
  border-right: none;
}