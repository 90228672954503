.pickersWrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 13.5px 16px;
  margin-top: 6px;
  font-size: 15px;
  border: 1px solid #c6c5b2;
  border-radius: 12px;
  position: relative;
}

.inputLabel {
  font-size: 14px;
  margin-bottom: 6px;
}

.timeSlotsText {
  display: inline-block;
  margin-top: 16px;
  font-size: 14px;
}

.participants {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.participantsLabel {
  font-weight: 600;
  margin-bottom: 6px;
}

.participantsInput {
  box-sizing: border-box;
  padding: 12.5px 16px !important;
  font-size: 15px;
  line-height: 21px;
  border: 1px solid var(--Colors-Neutral-gray-200, #dcdccc);
  border-radius: var(--br-12, 12px);
  width: 100%;
}

.participantsInput:disabled {
  background-color: var(--Colors-Neutral-gray-100, #edeee6);
}

.errorContainer {
  margin-top: 16px;
  color: var(--Colors-Text-text-error, #d0454c);
}