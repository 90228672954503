.footer {
  display: flex;
  gap: 10px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--br-8, 8px);
  align-self: stretch;
}

.footer i:global(.faded) {
  font-size: 20px;
  cursor: pointer;
}

.footer a:global(.btn) {
  color: var(--Components-Button-Primary-button-primary-text, #292929);
}
