.activityContainer {
  height: 100%;
}

.container {
  height: 100%;
  padding-bottom: 6px;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.content :global(.dnd-section) {
  padding-bottom: 24px;
}

.content:global(.view-results .drag-and-drop-list) {
  min-height: calc(100% - 40px);
}

.header {
  text-align: left;
  line-height: 22px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
}

.header ul {
  padding-left: 16px;
}

.header ul li {
  list-style: disc;
}
