.indicator {
  position: absolute;
  top: 8px;
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
  border-radius: 100%;
  z-index: 10;
  color: var(--Components-Button-Primary-button-primary-color, #FABB00);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
}

.indicator i {
  font-size: 10px;
}

.indicatorRight {
  right: 8px;
}

.indicatorLeft {
  left: 8px;
}

.indicatorLeft i:global(.fa-eye) {
  font-weight: 900;
}