.container {
  border-radius: 10px;
  background: var(--Colors-Neutral-gray-200, #edeee6);
  display: flex;
  padding: 4px;
  align-items: center;
  gap: var(--spacing--xxs, 2px);
}

.tab {
  padding: var(--spacing--sm, 8px) 14px;
  border-radius: 6px;
  color: var(--Colors-Text-text-secondary, #797466);
  transition: 0.3s;
  cursor: pointer;
}
.tab:global(.active) {
  color: var(--Colors-Text-text-primary, #292929);
  background: #fff;
}
