.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing--lg, 16px);
  min-height: calc(100vh - 80px);
}

.container> :global(.main-container) {
  flex: 1;
}

.container> :global(.main-container.row) {
  flex: 0;
}

.actionsInfo {
  display: flex;
  align-items: center;
  gap: var(--br-2xl, 16px);
}

.actionsInfo :global(.time) {
  color: var(--Colors-Functional-Info-600, #22b5d1);
}

.teamNameContainer {
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.teamNameContainer :global(.text) {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.teamNameContainer i {
  text-align: center;
  line-height: 100%;
  margin-right: 4px;
  font-size: 16px;
}

.textAreaWrapper {
  margin-top: 8px;
  min-width: 600px;
  max-width: 50vw;
  position: relative;
}

.textAreaWrapper :global(.cm-line) {
  color: var(--Colors-Text-text-primary, #292929);
}

.textAreaWrapper :global(.cm-placeholder) {
  color: var(--Colors-Text-text-faded, #C6C5B2);
}

.textAreaWrapper :global(.input-sync-disabled) {
  height: 26.4px;
}

.textAreaWrapper :global(.cm-content) {
  height: 60px;
}

.textAreaWrapper :global(.cm-line) {
  padding: 0px;
}

.textAreaWrapper :global(.cm-line),
.textAreaWrapper :global(.cm-placeholder),
.textAreaWrapper :global(.input-sync-disabled) {
  font-family: "Pally Variable";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.symbols {
  position: absolute;
  right: 6px;
  bottom: 3px;
  font-size: 12px !important;
  color: var(--Colors-Text-text-faded, #C6C5B2);
}

.instructions {
  display: inline;
}