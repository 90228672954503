.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--spacing--lg, 16px);
  overflow-y: auto;
  margin-bottom: 16px;
}

.detailsContainer {
  overflow: auto;
}

.container > :global(.main-container) {
  flex: 1;
}
.container > :global(.main-container.row) {
  flex: 0;
}
