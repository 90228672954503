.buttonIcon {
  margin-left: 8px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  text-align: left;
}

.titleMain {
  font-weight: 700;
  font-size: 40px;
  line-height: 40px;
  color: #1F1F23;
}

.titleSecondary {
  font-size: 16px;
  line-height: 18px;
  margin-top: 8px;
}

.titleSecondaryAccent {
  font-weight: 600;
}

.startJourneyButton {
  align-self: flex-start;
}