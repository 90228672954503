.breadcrumb {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 3px 26px;
  margin: 0;
  background-color: #F7F7F3;
  border-radius: 12px;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 16px; 
  width: fit-content;
  height: 30px;
}

.breadcrumbItem {
  display: flex;
  align-items: center;
  color: #A4A4A5;
  font-weight: 500;
  text-transform: capitalize;
}

.breadcrumbSeparator {
  margin: 0 0.5rem;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #A4A4A5;
}

.breadcrumbItemActive {
  color: #6c757d;
  pointer-events: none;
  font-weight: 600;
  color: #1F1F23;
}
