.rescheduleContent {
  display: flex;
  width: 794px;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: var(--br-8, 8px);
  background: var(--Colors-Neutral-gray-200, #edeee6);
}

.rescheduleContent > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.rescheduleContent div:global(.workshops-container) {
  max-height: 40vh;
  overflow: auto;
  padding: 16px;
}

.rescheduleContent div:global(.workshops-container .line) {
  height: 1px;
  align-self: stretch;
  background: var(--Colors-Neutral-gray-300, #dcdccc);
  flex-shrink: 0;
}

.rescheduleContent div:global(.workshops-container .workshop-content) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.rescheduleContent
  div:global(.workshops-container .workshop-content .workshop-button) {
  display: flex;
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--br-pill, 9999px);
  cursor: pointer;
}

.rescheduleContent
  div:global(.workshops-container .workshop-content .workshop-button.enrolled) {
  border: 1px solid var(--Colors-Green-200, #baf7d0);
  background: var(--Colors-Green-50, #f0fdf4);
}

.rescheduleContent
  div:global(
    .workshops-container .workshop-content .workshop-button.not-enrolled
  ) {
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
  border-color: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.rescheduleContent
  div:global(.workshops-container .workshop-content .workshop-button .icon) {
  display: flex;
  width: 12px;
  height: 12px;
  background-color: var(--Colors-Green-800, #166534);
  border-radius: 50%;
}

.rescheduleContent
  div:global(.workshops-container .workshop-content .enrolled .icon) {
  background-color: var(--Colors-Green-800, #166534);
}

.rescheduleContent
  div:global(.workshops-container .workshop-content .not-enrolled .icon) {
  background: var(--Colors-Functional-Danger-300, #f6d6d6);
}

.rescheduleContent div:global(.workshops-container .workshop-info) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.rescheduleContent div:global(.workshops-container .workshop-info) p {
  color: var(--Colors-Text-text-secondary, #797466);

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.noSlotsFound {
  min-height: 45px;
}

.inputContainer {
  width: calc(100% - 32px);
  padding: 6px 16px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: var(--br-10, 10px);
  border: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
  background: var(--Colors-Functional-Mono-white, #fff);
}

.inputContainer :global(.react-datepicker-wrapper) {
  flex: 1 1;
  text-align: left;
  color: var(--Colors-Neutral-gray-500, #9e9a86);
}

.datePicker {
  border: none !important;
}

.datePicker::placeholder {
  color: var(--Colors-Neutral-gray-500, #9e9a86);
}

.workshopBtn {
  display: flex;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: var(--br-10, 10px);
  border: 1px solid
    var(--Components-Button-Primary-button-primary-border, #f6be30);
  background: var(--Components-Button-Primary-button-primary-color, #f6be30);
  text-decoration: none;
  color: var(--Components-Button-Primary-button-primary-text, #444340);

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

.notFoundContainer {
  margin-top: -75px;
}
