.tooltipContent {
  padding: 8px 16px;
  border-radius: 5px;
  background-color: white;
  width: 290px;
  padding: 16px;
  text-align: left;
  border-radius: 16px;
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  box-shadow: 0px 12px 16px -4px rgba(17, 17, 20, 0.08),
    0px 4px 6px -2px rgba(0, 0, 0, 0.04);
  z-index: 999;
}

.tooltipBorder {
  border: 0.5px solid var(--Colors-Text-text-palest, #9e9a86);
}

.tooltipContent .title {
  margin-bottom: 8px;
}

.tooltipContent .description {
  color: var(--Colors-Neutral-gray-900, #292929);
}

.tooltipContent .warning {
  position: relative;
  top: 2px;
  color: var(--Colors-Functional-Danger, #d12d52);
  font-size: 20px;
  padding-right: 8px;
}

.tooltipContent[data-state="delayed-open"][data-side="top"] {
  animation-name: slideDownAndFade;
}

.tooltipContent[data-state="delayed-open"][data-side="right"] {
  animation-name: slideLeftAndFade;
}

.tooltipContent[data-state="delayed-open"][data-side="bottom"] {
  animation-name: slideUpAndFade;
}

.tooltipContent[data-state="delayed-open"][data-side="left"] {
  animation-name: slideRightAndFade;
}

.tooltipArrow {
  fill: white;
  filter: drop-shadow(0 0 2px var(--Colors-Text-text-palest, #9e9a86));
  clip-path: inset(0 -10px -10px -10px);
}

.closeBtn {
  position: absolute;
  top: 1px;
  right: 3px;
  color: var(--Colors-Text-text-palest, #9e9a86);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  cursor: pointer;
  padding: 8px;
}

.closeBtn:hover {
  color: #bfbebd;
  transition: 0.3s all;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
