.container {
  display: flex;
  gap: var(--Size-size-88, 88px);
  text-align: left;
  flex-direction: row;

  border-radius: var(--br-xl, 12px);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.content {
  width: 100%;
}

.translationsItemsContainer {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 10px;
  row-gap: 24px;
}

.translationsItemsHeader {
  display: contents;
}

.infoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing--16, 32px);
}
