.container {
  display: flex;
  justify-content: center;
  flex-shrink: 0;

  flex-direction: column;

  border-radius: var(--br-10, 10px);
  border: 1.5px solid
    var(--Components-Button-Primary-button-primary-color_hover, #fecd20);
  background: var(--Colors-Text-text-white, #fff);
  position: relative;
  overflow: hidden;
}

.container:global(.disabled) {
  border-color: var(--Colors-Neutral-gray-400, #bebeb3);
  background: var(--Colors-Neutral-gray-200, #edeee6);
  cursor: not-allowed;
}

.container:global(.aligned) {
  border-color: var(--Colors-Functional-Info, #1eaed0);
}
.container:global(.aligned):global(.correct) {
  border-color: var(--Colors-Functional-info, #1eaed0);
}
.container:global(.aligned):global(.correct):global(.closed) {
  background-color: var(--Colors-Functional-info-light, #edfcfe);
}

.container:global(.enforceAligned) {
  border-color: var(--Colors-Functional-Info, #1eaed0);
}
.container:global(.enforceAligned):global(.correct) {
  border-color: var(--Colors-Functional-success-dark, #15a457);
}
.container:global(.enforceAligned):global(.correct):global(.closed) {
  background: var(--Colors-Green-50, #f0fdf5);
}
.container:global(.enforceAligned):global(.wrong) {
  border-color: #941d3f;
}

/* .collapsibleContentContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
} */

.collapsibleContentContainerContent {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.collapsibleContentContainer[data-state="open"] {
  animation: slideDown 300ms ease-out;
}
.collapsibleContentContainer[data-state="closed"] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.separator {
  height: 1px;
  background: var(--Colors-Neutral-gray-300, #c6c5b2);
  margin-left: 20px;
  margin-right: 20px;
}

.collapseTrigger {
  padding: var(--spacing--16, 22px) 20px var(--spacing--16, 22px) 20px;
}

.collapseTrigger:global(.is-collapsible) {
  cursor: pointer;
}

.collapsibleIconContainer {
  width: 24px;
  height: 24px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.collapsibleIconContainer:global(.disabled) {
  cursor: not-allowed;
}

.badge {
  position: absolute;
  top: 0;
  right: 38px;
  padding: 5px 16px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  text-align: center;
  line-height: 14px !important;
  background: var(
    --Components-Button-Primary-button-primary-color_hover,
    #fecd20
  );
}

.badge:global(.enforceAligned) {
  background: var(--Colors-Functional-Info, #1eaed0);
  color: var(--Colors-Text-text-white, #fff);
}
.badge:global(.enforceAligned):global(.correct) {
  background: var(--Colors-Functional-success-dark, #15a457);
  color: var(--Colors-Text-text-white, #fff);
}
.badge:global(.enforceAligned):global(.wrong) {
  background: #941d3f;
  color: var(--Colors-Text-text-white, #fff);
}

.badge:global(.aligned) {
  background: var(--Colors-Functional-Info, #1eaed0);
  color: var(--Colors-Text-text-white, #fff);
}
.badge:global(.aligned):global(.correct) {
  background: #1eaed0;
  color: var(--Colors-Text-text-white, #fff);
}

span:global(.type) {
  text-transform: capitalize;
}

.toggleChevronContainer {
  top: -1px;
  right: 0px;
}

.section {
  padding: 0px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing--32, 32px);
  flex: 1 0 0;
}

.cardContent {
  display: flex;
  gap: 32px;
  align-self: stretch;
}

.leftSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.rightSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}

.header {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.sectionContent {
  display: flex;
  gap: 8px;
  align-self: stretch;
  padding: 12px 14px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
}

.infoContainer .explanation,
.sectionContent .description {
  margin-top: 12px;
}

.leftSection .sectionContent {
  padding: var(--Size-size-16, 16px) 8px var(--br-16, 16px) 20px;
  border-radius: 10px;
  border: 1.5px solid var(--Colors-Neutral-gray-100, #edeee6);
  background: #edeee6;
}

.leftSection .header {
  flex: 0;
}

.rightSection .sectionContent {
  display: flex;
  gap: 4px;
  align-self: stretch;
  padding: 0px;
}

.rightSection .sectionContent p:global(.description) {
  padding: 12px 16px;
  width: calc(100% - 32px);
  border-radius: var(--br-10, 10px);
  background: var(--Colors-Neutral-gray-100, #edeee6);
  word-break: break-word;
  outline: none;
  flex: 1;
  white-space: pre-wrap;
}

.cardFooter {
  position: relative;
  width: 100%;
}

.cardFooter:global(.line)::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 0.8px;
}

.optionsContainer {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-top: 16px;
  padding-bottom: 16px;
}

.option {
  cursor: pointer;
  display: flex;
  padding: var(--spacing--16, 16px) 20px;
  min-height: 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
  border-radius: var(--br-8, 8px);
  border: 2px solid
    var(--Components-Button-Outline-button-outline-color, #c6c5b2);
  background: var(--Colors-Main-white, #fff);
}

.option:global(.selected) {
  border-color: var(--Colors-Functional-Info-600, #22b5d1);
  border-style: dashed;
}

.option:global(.other-selected) {
  border-color: var(--Colors-Neutral-gray-700, #444340);
  border-style: dashed;
}

.option:global(.group-selected) {
  border-color: var(--Colors-Functional-Info-600, #22b5d1);
  border-style: dashed;
}

.optionSelect {
  cursor: pointer;
  width: calc(100% - 24px);
}

.iconContainer {
  flex: 1;
  display: flex;
  gap: 8px;
  flex-direction: row;
}
