.card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border: 1px solid var(--Colors-Neutral-gray-200, #DCDCCC);
  border-radius: 12px;
  gap: 16px;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  gap: 16px;
  font-size: 22px;
  font-family: "Pally Variable";
}

.content {
  width: 100%;
  overflow: auto;
}
