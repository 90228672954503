.connectionIssuesOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  background: rgb(247 247 243 / 50%);
}

.connectionIssuesOverlay h3 {
  background: var(--Colors-Text-text-primary, #292929);
  padding: 10px;
  border-radius: 10px;
  color: white;
}
