button {
  all: unset;
}

.trigger {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid var(--Colors-Neutral-gray-300, #ebeadf);
  padding: 12px;
  line-height: 1;
  height: 35px;
  min-width: 302px;
  gap: 5px;
  background: var(--Colors-Functional-Mono-white, #fff);
  color: var(--Colors-Text-text-primary, #363636);
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
}

.trigger:focus {
  box-shadow: 0 0 0 2px black;
}

.trigger:global(.type-grouping):focus {
  box-shadow: none;
}

.selectIcon {
  color: var(--Colors-Text-text-primary, #363636);
}

.content {
  z-index: 999;
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  box-shadow:
    0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.viewPort {
  padding: 12px;
}

.item {
  line-height: 1;
  color: var(--Colors-Text-text-secondary, #797466);
  display: flex;
  align-items: center;
  height: 25px;
  padding: 10px 12px;
  padding-right: 35px;
  position: relative;
  user-select: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  cursor: pointer;
}

.item[data-disabled] {
  pointer-events: none;
}

.item[data-highlighted] {
  outline: none;

  border-radius: var(--br-12, 12px);
  background: var(--Colors-Functional-Success-100, #e8fcef);
  color: var(--Colors-Text-text-primary, #292929);
}

.item:global(.type-grouping)[data-highlighted] {
  border-radius: var(--br-8, 8px);
  background: var(--Colors-Neutral-gray-100, #EDEEE6);
}

.item:global(.type-grouping):global(.selected) {
  outline: none;
  border-radius: var(--br-8, 8px);
  background: var(--Colors-Brand-brand-primary, #FABB00);
  color: var(--Colors-Text-text-primary, #292929);
}

.label {
  padding: 0 25px;
  font-size: 12px;
  line-height: 25px;
}

.separator {
  height: 1px;
  margin: 5px;
}

.indicator {
  position: absolute;
  right: 12px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.scrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  cursor: default;
}