.dialogTitle {
  margin-bottom: 24px !important;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dialogFooter {
  display: flex;
  margin-top: 25px;
  gap: 16px;
  justify-content: flex-end;
}

.dialogCloseButton {
  top: 24px;
  right: 20px;
}

.dialogCloseButton svg {
  width: 20px;
  height: 20px;
}