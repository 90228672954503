.dialogContent {
  display: flex;
  flex-direction: row;
  align-content: center;

  border-radius: var(--br-12, 12px);

  background: var(--Colors-Functional-white, #fff);
  box-shadow: 0px 1px 3px 0px rgba(17, 17, 20, 0.08),
    0px 1px 2px 0px rgba(17, 17, 20, 0.06);
}

.dialogContent .success {
  border: 1px solid var(--Colors-Green-400, #49df8e);
}

.dialogContent .error {
  border: 1px solid var(--Colors-Functional-danger, #d12d52);
}

.dialogContent .icon {
  display: flex;
  align-items: center;
}

.dialogContent .icon .success {
  border: 0;
  padding-top: 2px;
  margin: -2px 20px 2px -5px;
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 20px;
  font-weight: 400;
}

.dialogContent .icon .error {
  border: 0;
  padding-top: 2px;
  margin: -2px 20px 2px -5px;
  font-size: 20px;
}

.dialogContent .icon .success {
  color: var(--Colors-Green-400, #49df8e);
}

.dialogContent .icon .error {
  color: var(--Colors-Functional-danger, #d12d52);
}

.dialogContent .texts.multiple {
  font-size: 14px;
}

.dialogContent .texts .heading {
  color: var(--Colors-Text-text-primary, #363636);
  font-weight: 600;
}

.dialogContent .texts .description {
  color: var(--Colors-Text-text-primary, #363636);
  margin-top: 10px;
}
