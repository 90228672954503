.translationItemContainer {
  display: contents;
}

.translationItemColLeft {
  background-color: #EDEEE6;
  padding: 13px 16px;
  border-radius: 10px;
  color: #292929;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.translationItemColMid {
  display: flex;
  align-items: center;
  justify-content: center;
}

.translationItemColMidIcon {
  text-align: center;
  color: #AFAC95;
  font-size: 24px;
  font-weight: 300 !important;
  word-wrap: break-word
}

.textAreaWrapper {
  text-align: left;
}

.textAreaWrapper :global(.textarea-sync-disabled) {
  min-height: 21px;
}

.textAreaWrapper :global(.cm-line) {
  padding-left: 0px;
  color: var(--Colors-Text-text-primary, #292929);
}

.textAreaWrapper :global(.cm-placeholder) {
  color: var(--Colors-Neutral-gray-500, #9E9A86);
}

.textAreaWrapper :global(.cm-line),
.textAreaWrapper :global(.cm-placeholder) {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}