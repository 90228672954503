.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
  height: 100%;
  overflow: auto;
}

.container h3 {
  padding-left: 10px;
  font-weight: 700;
}

.workshopListHeader {
  display: flex;
  gap: 20px;

  border-bottom: 1px solid var(--Colors-Neutral-gray-200, #dcdccc);
  padding: 0 16px 8px;
}

.workshopListBody {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.workshop {
  padding: 8px;
}

.workshop:not(:global(.collapsed)):hover {
  background: var(--Colors-Neutral-gray-50, #f7f7f3);
}

.workshop+.workshop {
  border-top: 1px solid var(--Colors-Neutral-gray-200, #dcdccc);
}

.workshopContent {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.workshopContent .collapseControlColumn:hover {
  background-color: rgba(145, 142, 126, 0.2);
  cursor: pointer;
}

.workshopLine {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.workshop:global(.collapsed) {
  padding: 8px 0;
}

.workshop:global(.collapsed):last-child {
  padding: 8px 0 0 0;
}

.workshop:global(.collapsed) .workshopContent {
  padding: 8px 16px;
  border-radius: var(--br-12, 12px);
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.workshop:global(.collapsed) .lineSection.collapseControlColumn {
  rotate: 180deg;
}

.lineSection {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
}

.lineSection div {
  display: flex;
  align-items: center;
  gap: 6px;
}

.lineSection.durationColumn {
  flex: 0 0 80px;
}

.lineSection.statusColumn {
  flex: 0 0 80px;
}

.lineSection.actionColumn {
  flex: 0 0 155px;
  display: flex;
  justify-content: flex-end;
  transition: 0.3s all;
}

.lineSection.actionColumn a:hover {
  transition: 0.3s all;
  background-color: var(--Colors-Brand-brand-primary, #edb72e);
}

.lineSection.collapseControlColumn {
  flex: 0 0 32px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 100%;
}

.workshopStatus {
  text-transform: capitalize;
}

.workshopCollapsedData {
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  border-top: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
  margin-top: 24px;
  display: none;
}

.workshopCollapsedData:global(.visible) {
  display: flex;
}

.collapseSection {
  flex: 8;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.collapseSection:global(.video) {
  flex: 4;
}

.videoContainer {
  height: 100%;
}

.videoContainer video {
  height: auto;
  position: inherit;
  transform: none;
  top: 0;
  left: 0;
}

.authorContainer {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.authorImage {
  position: relative;
  overflow: hidden;

  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--Colors-Text-text-secondary, #797466);
}

.authorImage div:global(.image-abbreviation) {
  background-color: transparent;
}

.authorImage div:global(.loader) {
  border-width: 2px;
  border-color: white transparent white transparent;
}

.authorInfo {
  display: flex;
  flex-direction: column;
  width: calc(100% - 48px);
}

.scoreContainer {
  border-top: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
  border-bottom: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
  padding: 8px 0px 8px 8px;
  display: flex;
  gap: 20px;
}

.scoreSection {
  flex: 1;
}

.objectives {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.objective {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.objective i:global(.icon) {
  background: var(--Colors-Brand-brand-primary, #f6be30);
  color: var(--Colors-Functional-Mono-white, #fff);
  border-radius: 100%;
  text-align: center;
  font-size: 11px;
  line-height: 16px;
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
}

.loaderContainer {
  width: 100%;
  height: calc(100vh - 80px);
}

.loaderContainer div:global(.loader) {
  width: 80px;
  height: 80px;
}