.toggle {
  color: var(--Colors-Text-text-secondary, #797466);
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 10px;
  border-radius: 50%;
  font-size: 12px;
  transition: 0.3s;
  cursor: pointer;
}

.toggle:hover {
  background-color: var(--Components-Button-Secondary-button-secondary-color-hover, #EDEEE6);
}

.toggle.expanded {
  transform: rotate(180deg);
}

.toggleIcon {
  background: transparent !important;
  margin: 0 !important;
}