.DropdownMenuContent {
  min-width: 220px;
  padding: 12px;
  border-radius: 8px;
  background: var(--Colors-Functional-white, #FFF);
  box-shadow: 0px 20px 24px -4px rgba(17, 17, 20, 0.08), 0px 8px 8px -4px rgba(17, 17, 20, 0.03);
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  position: relative;
  z-index: 9;
}

.chevronIconButton {
  padding: 0px !important;
  cursor: pointer;
  display: flex;
}

.chevronIconButton:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.DropdownMenuRadioItem {
  outline: none;
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--br-8, 8px);
}

.DropdownMenuRadioItem[data-state="checked"] {
  opacity: 0.3;
}

.DropdownMenuRadioItem:hover {
  background: var(--Colors-Neutral-gray-100, #EDEEE6);
}

.DropdownMenuGroupLabel {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  color: var(--Colors-Text-text-secondary, #797466);
}

.DropdownMenuLabel {
  flex: 1 1;
}

.DropdownMenuSeparator {
  height: 1px;
  background-color: var(--violet-6);
  margin: 5px;
}

.DropdownMenuItemIndicator {
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.DropdownMenuItemIndicator i {
  width: 12px;
  height: 12px;
  color: var(--Colors-Functional-success-dark, #15A457);
}

.DropdownMenuArrow {
  fill: white;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--violet-11);
  background-color: white;
  box-shadow: 0 2px 10px var(--black-a7);
}

.IconButton:hover {
  background-color: var(--violet-3);
}

.IconButton:focus {
  box-shadow: 0 0 0 2px black;
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(2px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}