.brainstormingItemContainer {
  position: relative;
}

.content {
  display: flex;
  padding-right: 3px;
}

.info {
  flex: 0 0 102px
}

.infoImageContainer img {
  height: 102px;
  width: 102px;
}

.infoImageContainer:global(.disabled) {
  opacity: .5;
}

.inputContainer {
  flex: 1;
}

.inputContainer textarea {
  height: calc(100px - 16px - 16px);
}

.textareaWithImage {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.inputContainer textarea:disabled {
  color: var(--Colors-Neutral-gray-700, #797466);
}

.actionContainer {
  position: absolute;
  right: 6px;
  bottom: 8px;
}

.actionContainer button {
  padding: 9px var(--spacing--12, 10px) !important;
  border-radius: var(--br-6, 6px);
  width: 12px;
  overflow: hidden;
  transition: .3s;
  border: none;
  justify-content: flex-end !important;
}

.actionContainer :global(.button-text) {
  display: none;
}

.actionContainer button i {
  width: 12px;
  height: 12px;
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 12px;
}

.actionContainer button:hover :global(.button-text) {
  display: block;
}

.actionContainer button:hover {
  width: 76px;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  gap: 6px !important;
}

.actionContainer button:disabled {
  opacity: 1 !important;
  background: var(--Components-Button-Utility-button-disabled-color, #EDEEE6) !important;
  border-color: var(--Components-Button-Utility-button-disabled-color, #EDEEE6) !important;
}

.actionContainer button:disabled :global(.button-text) {
  color: var(--Components-Button-Utility-button-disabled-text, #C6C5B2);
}

.actionContainer button:disabled i {
  color: var(--Components-Button-Utility-button-disabled-text, #C6C5B2);
}