.mutedVideoContainer {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  container-type: size;
  color: white;
  position: absolute;
  width: 100%;
  top: 0;
  background-color: var(--Colors-Neutral-gray-200, #EDEEE6);
  z-index: 3;
}

.mutedVideoContainer i {
  font-size: 72cqmin;
}

.imageContainer:global(:not(.hidden)),
.videoContainer:global(:not(.hidden)) {
  opacity: 1;
  animation: fadeIn 0.3s ease-in;
  height: 100%;
}

.videoContainer:global(.hidden),
.imageContainer:global(.hidden) {
  opacity: 0;
}

.imageContainer div:global(.loader) {
  width: 50px;
  height: 50px;
  border-color: #FFF transparent #FFF transparent;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}