.footer {
  display: flex;
  gap: 10px;
}

.line {
  display: flex;
  align-items: center;
  gap: 16px;
}

.line + .line {
  margin-top: 16px;
}

.lineSection {
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
}

.lineSection:global(.picker-container) {
  gap: 0px;
}

.lineSection :global(.picker-title) {
  margin-bottom: 6px;
}

.workshopScheduledImageContainer {
  display: flex;
  padding: 70px 36px 70px 36px;

  justify-content: center;
  align-items: center;
}

.slotDetails {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.slotDetails :global(.btn) {
  width: fit-content;
}

.selector {
  width: calc(100% - 24px) !important;
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--br-8, 8px);
  align-self: stretch;
}

.footer i:global(.faded) {
  font-size: 20px;
  cursor: pointer;
}

.footer a:global(.btn) {
  color: var(--Components-Button-Primary-button-primary-text, #292929);
}

.hoverCardContent {
  max-width: 260px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--br-10, 10px);
  background: var(--Colors-Functional-Mono-white, #fff);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;
  z-index: 999;
}

.hoverCardContent div:global(.hover-card-header) {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.hoverCardContent div:global(.hover-card-body) {
  color: var(--Colors-Text-text-secondary, #797466);
  /* Content/Small Bold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
  padding-left: 28px;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: column;
}

.hoverCardContent div:global(.hover-card-body) ul {
  padding-left: 20px;
  list-style-type: disc;
}

.hoverCardArrow {
  width: 20px;
  height: 8px;
  fill: var(--Colors-Functional-Mono-white, #fff);
}

.loadingContainer {
  margin: 50px auto;
}

.loadingContainer div {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.errorContainer {
  margin-top: 16px;
  color: var(--Colors-Text-text-error, #d0454c);
}
