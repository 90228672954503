.container {
  margin-top: 40px;
}

.title {
  text-align: left;
  line-height: 40px;
}

.achievementsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  padding: 20px 0 22px;
  background-color: #F7F7F3;
  border: 1px solid #BFBEB6;
  border-radius: 12px;
}