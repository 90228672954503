.ahaMomentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;

  overflow-x: auto;
}

.overviewContainer::-webkit-scrollbar {
  display: none;
}

.headerContainer {
  border-bottom: 1px solid #ccc;
  padding-bottom: 16px;
}

.sectionContainer {
  overflow-x: auto;
}

.sectionCard {
  border-radius: var(--br-12, 12px);
  border: 1px solid var(--Colors-Neutral-gray-200, #DCDCCC);
  background: var(--Colors-Functional-Info-100, #F6FEFF);
}

.sectionCard :global(.section-card-content) {
  overflow: visible;
}

.ahaMomentContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.ahaMoments {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ahaMoment {
  display: flex;
  padding: 13.5px 18.5px;
  align-items: flex-start;
  gap: var(--Size-size-4, 4px);
  align-self: stretch;
  border-radius: 10px;
  border: 1.5px solid var(--Colors-Neutral-gray-400, #BEBEB3);
  background: #FFF;
}

.ahaMoment span:global(.moment-text) {
  display: flex;
  flex: 10;
  text-align: left;
  word-break: break-word;
  white-space: pre-wrap;
}

.ahaMoment span:global(.author-moment) {
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0px 12px;
  border-radius: var(--spacing--4, 4px);
  border: 1px solid var(--Components-Badge-badge-primary-border, #FECD20);
  background: var(--Components-Badge-badge-primary-color, #FEF8E1);

  color: var(--Components-Badge-badge-primary-text, #874B0C);
  text-align: center;

  /* Content/Small Bold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 142.857% */
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actions button i {
  font-size: 12px;
}

.userIcon {
  width: 22px;
  height: 22px;
  font-size: 9px;
  padding: 0px;
}

.shareButton {
  align-self: flex-end;
}
