.dragAndDropSection {
  display: flex;
  flex: 1;
}

.delimiter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  position: relative;
}

.iconContainer {
  position: absolute;
  top: 0px;
  display: flex;
  height: 32px;
  padding-top: 16px;
  padding-bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.draggingCard {
  border-color: var(--Colors-Brand-brand-primary, #FABB00);
  background: white;
  box-shadow: 0px 8px 16px 0px rgba(82, 82, 61, 0.12);
}

.leftList {
  padding-right: 24px;
  border-right: 1px solid #BEBEB3;
}
.rightList {
  padding-left: 24px;
}