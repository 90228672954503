.container {
  display: flex;
  gap: var(--Size-size-88, 88px);
  text-align: left;
  flex-direction: row;
}
.infoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing--24, 24px);
  min-width: 330px;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  gap: var(--spacing--16, 16px);
}

.sharedValuesContainer {
  min-width: 330px;
  flex: 1;

  display: flex;
  padding-top: 22px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing--16, 16px);
}

.sharedResult {
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  border-radius: var(--br-10, 10px);
  background: var(--Colors-Neutral-gray-100, #edeee6);
}

.sharedResultTitleRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.noSubmittedValuesYet {
  display: flex;
  padding: var(--spacing--16, 16px) 16px;

  align-items: center;
  gap: var(--spacing--16, 16px);

  align-self: stretch;
  border-radius: var(--br-12, 12px);
  border: 1px solid var(--Colors-Neutral-gray-300, #c6c5b2);
  box-shadow: 0px 1px 2px 0px rgba(17, 17, 20, 0.06);
}
