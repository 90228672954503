.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 80vh;
  overflow: auto;
  overflow-x: hidden;
}

.slotItem {
  padding: 8px;
}

.slotItem+.slotItem {
  border-top: 1px solid var(--Colors-Neutral-gray-200, #EDEEE6);
}

.loadingContainer {
  margin: 50px auto;
}

.loadingContainer div {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}