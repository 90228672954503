.dialogContent {
  max-width: 502px;
}

.content {
  display: flex;
  justify-content: space-between;
}

.formField {
  display: flex;
  flex-direction: column;
}

.label {
  margin-top: 2px;
}

.inputField {
  margin-top: 6px;
}

.button {
  font-size: 15px;
  width: 70px;
  align-self: flex-end;
  text-align: center;
}
