.journeyConversationsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.journeyConversation {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.journeyConversationArrow {
  position: absolute;
  bottom: -31px;
  left: 53px;
  height: 31px;
  width: 1px;
  background-color: var(--Colors-Neutral-gray-300, #C6C5B2);
}

.journeyConversationArrow:global(.completed) {
  background-color: var(--Colors-Green-400, #49DF8E);
}

.journeyImageSkeletonLoader {
  position: relative;
  top: -1px;
}

.journeyTextSkeletonLoader {
  position: relative;
  top: -1px;
}