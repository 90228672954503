.timerWrapper {
  position: relative;
  width: calc(70px - 25px);

  border-radius: var(--spacing--sm, 8px);
  background: var(--Colors-Functional-Info-200, #ebf5f7);
  padding: 3.5px 12.5px 3.5px 12.5px;
}

.timerWrapper:global(.is-passed-time) {
  width: auto;
}

.timerWrapper:global(.is-red) {
  background: var(--Colors-Functional-danger-light, #fad1d6);
}

.timerWrapper:global(.is-red .timer-value) {
  color: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.timerWrapper div:global(.timer-value) {
  color: var(--Colors-Functional-Info-600, #22b5d1);
}

.timerWrapper div:global(.timer-container) {
  display: flex;
  position: absolute;
  top: 18px;
  left: 131px;
  gap: 16px;
}

.timerWrapper div:global(.timer-container.inner) {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;

  padding: 0;
  border-radius: var(--spacing--sm, 8px);
}

.timerWrapper:global(.is-passed-time .timer-container.inner) {
  width: 100%;
  height: 100%;
}

.timerWrapper div:global(.timer-container .progress-color-container) {
  background: var(--Colors-Functional-Info-600, #22b5d1);
  overflow: hidden;
  border-radius: var(--spacing--sm, 8px);
}

.timerWrapper div:global(.timer-container .progress-color-container.is-red) {
  color: var(--Colors-Functional-Error-600, #ff6b6b);
  background: var(--Colors-Functional-Error-600, #d12d52);
}

.timerWrapper
  div:global(
    .timer-container .progress-color-container .progress-text-container
  ) {
  width: calc(70px - 25px);
  padding: 4px 12.5px;
}

.timerWrapper:global(
    .is-passed-time
      .timer-container
      .progress-color-container
      .progress-text-container
  ) {
  width: max-content;
}

.timerWrapper
  div:global(
    .timer-container
      .progress-color-container
      .progress-text-container
      .timer-value
  ) {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--Colors-Neutral-gray-100, #f7f7f3);
}
