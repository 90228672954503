.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;

  border-radius: var(--br-16, 16px);
  background: var(--Colors-Neutral-gray-50, #f7f7f3);

  height: calc(100vh - 80px - 18px);
  overflow: auto;
}

.loginContainer h3:global(.title) {
  color: var(--Colors-Text-text-secondary, #5c5c5c);
  text-align: center;
  padding-bottom: 16px;
}

.loginContainer input:global(.FormControl) {
  text-align: left;
}

.loginContainer a:global(.forgot-password) {
  color: var(--Colors-Text-text-primary, #292929);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
}

.loginContainer div:global(.error) {
  color: var(---Colors-Functional-Danger-700, #b91c1c);
}

.pageTitle {
  margin-top: 32px;
}

.loginSections {
  display: flex;
}

.loginSection {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginSection + .loginSection::after {
  content: "";
  height: calc(100% - 24px - 24px);
  width: 1px;
  background-color: var(--Colors-Neutral-gray-300, #c6c5b2);
  position: absolute;
  left: 0;
  top: 24px;
}

.invitationInfo {
  text-align: left;
  margin-top: 1px;
  min-height: calc(76px - 12px - 16px);
}

.formContainer {
  background-color: transparent;
  padding-top: 24px;
  padding-bottom: 24px;
}

.forgotPassword {
  height: 40px;
  font-weight: 500;
  line-height: 40px;
  text-decoration: none;
  text-decoration-line: none;
}

.footerMessage {
  width: calc(400px - 64px);
}

.footerMessage :global(a.underline) {
  color: var(--Colors-Text-text-primary, #292929);
}
