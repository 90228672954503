.container {
  height: 24px;
  width: 24px;
  min-width: 24px;
  background: var(--Colors-Functional-Info-400, #C6C5B2);
  color: var(--Colors-Text-text-primary, #292929);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.container.selected {
  background: var(--Colors-Functional-Info-400, #7cddf0);
}
