.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderContainer div:global(.loader) {
  width: 50%;
  height: 50%;
  border: solid 5px #000000;
  border-radius: 50%;
  border-color: #000000 transparent #000000 transparent;
  transition: transform 0.5s ease-in;
  animation-name: rotate;
  animation-duration: 1.0s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}