.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 12px;
  padding-bottom: 20px;
}

.container :global(.spacer) {
  height: 12px;
}

.journeyTitle {
  margin-bottom: 8px;
}
.journeyTitle.loader {
  margin-top: -2px
}

.journeyCoverImageContainer img {
  max-height: 135px;
}
