.container {
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid var(--Colors-Neutral-gray-300, #c6c5b2);
  position: relative;
  box-shadow: 0px 1px 2px 0px #1111140f;
  position: relative;
}

.containerDanger {
  border-color: var(--Colors-Functional-danger, #d12d52);
  background-color: white;
}

.containerDanger .titleContainer,
.containerDanger .title {
  color: var(--Colors-Functional-danger, #d12d52);
}

.titleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.description {
  text-align: left;
}

.description.isDismissible {
  width: calc(100% - 64px);
}

.dismissButton {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 13px;
  border-radius: 50%;
}

.dismissButton:hover {
  background-color: var(
    --Components-Button-Secondary-button-secondary-color-hover,
    #edeee6
  );
}

.container i:global(.icon-dismiss) {
  color: var(--Colors-Neutral-gray-400, #afac95);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  cursor: pointer;
}

.transparent {
  background: transparent;
}

.title:global(.text.small.bold) {
  font-weight: 600;
}
