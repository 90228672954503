.journeyGoal {
  padding: 12px 24px;
  color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.imagesContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 26px;
  gap: 10px;
}

.imagesContainer img {
  width: 43%;
  max-width: 100%;
}

.arrowIcon {
  font-size: 30px;
  color: rgba(254, 205, 32, 1);
}

.title {
  font-weight: 500;
  line-height: 40px;
  font-size: 32px;
  text-align: left;
}

.description {
  text-align: left;
  color: #F7F7F3;
  font-size: 18px;
  margin-top: 16px;
}

.descriptionAccent {
  font-weight: 700;
}