.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.topSection {
  display: flex;
  flex-direction: column;
  gap: 32px;
  min-width: 550px;
  text-align: left;
}

.topic {
  color: var(--Colors-Text-text-secondary, #797466);
  /* font-family: Inter; */
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 141%;
  margin: 0;
}

.dataSectionsContainer {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.dataSections {
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(1, 1fr);
}

.dataSectionsContainer .dataSections {
  margin-top: 16px;
  margin-right: -19px;
  width: calc(100% + 19px);
}

.dataSection {
  flex: 1;
  border-top: 1px solid var(--Colors-Neutral-gray-400, #C6C5B2);
  padding: 16px 19px 0 0;

  flex-direction: column;
  align-items: flex-start;
  gap: 12px;

  display: flex;
}

.metricsSection {
  gap: 0;
}

.dataSectionTitle {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.dataSectionTitle>img {
  margin-right: 8px;
}

.objectivesHeading {
  margin-bottom: 8px;
}

.authorContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
}

.authorContainer>div {
  display: flex;
  gap: 17px;
}

.imgContainer {
  height: 100px;
  width: 100px;
  flex-shrink: 0;
  background: lightgray 50% / cover no-repeat;
  border-radius: 6px;
  overflow: hidden;
}

.imgContainer img {
  width: 100%;
  height: 100%;
}

.authorInfo {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
}

.statisticContainer {
  display: flex;
  align-items: baseline;
  line-height: 35px;
  margin-top: 9px;
}

.statisticContainer> :global(.text.small) {
  margin-left: 8px;
  font-size: 14px;
}

.objectivesContainer {
  display: flex;
  flex-direction: column;
  gap: var(--br-16, 16px);
  width: 100%;
  text-align: left;
}

.objective {
  display: flex;
  gap: var(--br-16, 16px);
}

.icon {
  width: 16px;
  height: 16px;
  padding: 4px 0px;
}

:global(.fa).circleCheckIcon {
  line-height: 140%;
}

.duration,
.participantsCount {
  font-family: "Pally Variable";
  font-size: 32px;
  font-weight: 500;
}

.authorInfoContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 16px;
}