.emotion {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: var(--br-8, 8px);
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 0px 4px 0px rgba(235, 234, 223, 0.1);
  cursor: pointer;
}

.emotion:global(.disabled) {
  cursor: default;
}

.emotion:global(.is-loading) {
  cursor: progress;
}

.emotion:hover {
  background-color: var(--Colors-Emotion-Hover, #fee8b1);
  transition: 0.3s;
}

.emotion:global(.disabled):hover,
.emotion:global(.is-loading):hover {
  background: rgba(255, 255, 255, 0.5);
}

.emotion:global(:not(.disabled)) .emotionInner,
.emotion:global(:not(.is-loading)) .emotionInner {
  transition: 0.2s;
  background-color: var(--Colors-Emotion-Primary, #F6BE30);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: calc(80px - 16.77px - 16.77px);
  height: calc(80px - 16.77px - 16.77px);
}

.emotionInner img {
  transition: 0.2s;
  width: 21.25px;
  height: 15px;
}

.emotion:global(.active) .emotionInner {
  border-radius: var(--br-8, 8px);
  width: 80px;
  height: 80px;
}

.emotion:global(.active) .emotionInner img {
  width: 40px;
  height: 28px;
}

.emoji {
  font-size: 100px;
}