.journeyImage {
  position: relative;
}

.journeyImage .content {
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 14px;
}

.journeyImage .content div:global(.loader) {
  width: 80px;
  height: 80px;
}

.journeyImage .authorImage {
  width: 90px;
  height: 96px;
  flex-shrink: 0;
}

.journeyImage .authorImage img {
  width: 100%;
  object-fit: contain;
  border-radius: 8px;
  border-radius: 12px;
  border: 1px solid #bfbeb6;
}

.journeyImage .contentBackground {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0px 12px 0px 12px;
  width: 75%;
}

.journeyImage .contentBackground .authorInfo h2,
.journeyImage .contentBackground .authorInfo p {
  color: white;
}

.journeyImage .content .authorImage {
  width: 90px;
  height: 96px;
  border-radius: 8px;
}

.journeyImage .content .authorImage img {
  max-width: 100%;
  object-fit: contain;
  border-radius: 12px;
  border: 1px solid #bfbeb6;
}

.journeyImage .authorInfo h2 {
  color: #1f1f23;
  font-family: "PP Neue Montreal";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.04px;
  text-align: left;
}

.journeyImage .authorInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 10px;
}

.authorHeadline {
  color: #1f1f23;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.03px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  width: 250px;
  text-align: left;
}

.journeySection {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.journeySection .journeyImage {
  position: relative;
  width: 35%;
  height: auto;
  display: block;
  border-radius: 12px;
  background: #f7f7f3;
  height: 400px;
}

.journeySection .journeyBannerImage {
  position: absolute;
  max-width: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 8px;
  border-radius: 12px;
  border: 1px solid #bfbeb6;
}

.journeySection div:global(.loader) {
  width: 80px;
  height: 80px;
}

.journeySection .journeyCard {
  display: flex;
  flex-direction: column;
  width: 65%;
  height: auto;
  padding: 40px 44px;
  border-radius: 12px;
  border: 1px solid #bfbeb6;
  background: rgba(247, 247, 243, 0.1);
}

.journeySection .journeyBtn {
  display: block;
  color: #1f1f23;
  margin: 12px 0px;
  width: 238px;
  margin-top: 20px;
  text-align: center;
}

.journeyCard .tags {
  display: flex;
  gap: 10px;
  align-items: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  font-weight: 500;
  font-size: 14px;
}

.journeyCard .tags .activeTag {
  padding: 7px 14px;
  border-radius: 8px;
  border-radius: 8px;
  background: #22b5d1;
  color: white;
}

.journeyCard .info {
  text-align: left;
}

.journeyCard .info h2 {
  color: #1f1f23;
  font-family: "PP Neue Montreal";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0.08px;
  padding: 10px 0px;
  margin: 12px 0px;
}

.longDescription {
  color: #797466;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.027px;
}

.journeyListAggregates :global(.details-logo) {
  font-size: 20px;
}

.journeyListAggregates :global(.aha-logo) {
  font-size: 10px;
}

.journeyListAggregates :global(.aggregate-top-title) {
  font-size: 14px;
}

.journeyListAggregates :global(.journey-details-item) {
  padding: 0;
}

.status {
  padding: 7px 14px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: #22B5D1;
}