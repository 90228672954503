.field {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.iconContainer {
  position: absolute;
  right: 16px;
  top: 50%;
  cursor: pointer;
  width: 20px;
  color: var(--Colors-Neutral-gray-700, #797466);
}
