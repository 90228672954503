.updatePasswordContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: var(--Colors-Neutral-gray-50, #f7f7f3);
  position: relative;

  height: calc(100vh - 80px - 18px);
  overflow: auto;
}

.updatePasswordContainer input:global(.FormControl) {
  text-align: left;
}

.updatePasswordContainer .label {
  text-align: left;
  display: block;
}

.updatePasswordContainer button:global(.submit-btn) {
  margin-top: 8px;
  height: 56px;
}

.updatePasswordContainer div:global(.error) {
  color: var(---Colors-Functional-Danger-700, #b91c1c);
}

.updatePasswordContainer div:global(.login-link) {
  color: var(--Colors-Text-text-secondary, #797466);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.updatePasswordContainer div:global(.login-link) a {
  text-decoration-line: underline;
  color: var(--Colors-Text-text-secondary, #797466);
}

.titleContainer {
  margin-bottom: 24px;
}

.titleContainer .steps {
  color: var(--Colors-Text-text-faded, #c6c5b2);
}

.titleContainer .emailInfo {
  margin-top: 8px;
}

@media (max-height: 800px) {
  .copyright {
    width: 100%;
  }
}