.iframeContainer {
  width: 100%;
  height: 100%;
  min-height: 232px;
  display: flex;
  flex: 1;
  overflow: hidden;
  background: #fff0;
  border-radius: var(--br-8, 8px);
}

.videoContainer {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff0;
  border-radius: var(--br-8, 8px);
}

.videoContainer > video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.videoControlsHidden video::-webkit-media-controls {
  display: none !important;
}

.videoControlsHidden video::-webkit-media-controls-panel {
  display: none !important;
}

.videoControlsHidden video::-webkit-media-controls-start-playback-button {
  display: none !important;
  pointer-events: none;
}

.iframeContainer iframe {
  width: 100%;
}

.customPlayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FFFB;
  padding: 0px;
  cursor: pointer;
  z-index: 10;
}

.customPlayButton.hidden {
  display: none;
}

.customPlayButton.large {
  font-size: 96px;
}

.customPlayButton.small {
  font-size: 48px;
}