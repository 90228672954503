.cardBody {
  display: flex;
  border-radius: 12px;
  border: none;
  padding: 24px;
  background: var(--Colors-Yellow-50, #FEF9E2);
}

.cardBody:global(.link) {
  background: var(--Colors-Cyan-50, #EDFCFE);
}

.content {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
}

.content>div {
  width: 100%;
}

.cardBody:global(.conversation) .content {
  flex-direction: row;
}

.cardBody:global(.conversation) .content>div:not(:has(.iconContainer)) {
  width: auto;
}

.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 48px;
  padding: 8px;
  border-radius: var(--br-pill, 9999px);
  background: var(--Colors-Yellow-200, #FEF3C7);
  position: relative;
  z-index: 0;
}

.iconContainer i {
  color: white;
}

.innerIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px;
  border-radius: var(--br-pill, 9999px);
  background: var(--Colors-Yellow-400, #FECD20);
}

.contentContainer {
  display: flex;
  flex: 10;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing--4, 16px);
}

.conversationContainer {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.contentText {
  color: var(--Colors-Text-text-primary, #292929);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  text-align: left;
}

.contentText strong {
  color: var(--Colors-Text-text-primary, #292929);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}

.actionButton {
  gap: 9px !important;
}

.actionButton i, .iconContainer i {
  font-size: 12px;
  width: 12px;
  height: 12px;
}

.imgContainer {
  height: 40px;
  width: 40px;
  flex-shrink: 0;
  background: #fff0 / cover no-repeat;
  border-radius: 50%;
}

.imgContainer img:global(.image) {
  border-radius: 50%;
}

.imgContainer div:global(.image-loading) {
  width: 100px;
  height: 100px;
}

.imgContainer div:global(.image-error) {
  font-size: 50px;
  color: white;
}

.contentSep {
  height: 1px;
  width: 100%;
  margin: 0px;
  border: none;
  background-color: #FCE399;

}

.cardBody:global(.link) .contentSep {
  background: var(--Colors-Cyan-200, #ABEAF6);
}

.contentDocumentFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cardBody:global(.conversation) .contentDocumentFooter {
  justify-content: flex-end;
}

.contentDocumentFooterAuthor {
  display: flex;
  flex-direction: row;
}

.contentDocumentFooterAuthor div:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 8px;
  font-size: 14px;
}

.contentDocumentFooterAuthor div span:nth-child(2) {
  color: #797466;
}