.collapsibleContainer {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 16px 20px;
  background-color: white;
  border-radius: 10px;
  border: 1.5px solid var(--Colors-Neutral-gray-400, #AFAC95);
  position: relative;
  font-size: 14px;
}
.collapsibleContainer :global(.toggle-chevron-container) {
  top: 17px;
}

.collapsibleTrigger {
  align-self: flex-start;
}

.radioItem {
  display: flex;
  font-weight: 600;
  cursor: pointer;
}

.collapsibleContainerChecked {
  background-color: var(--Colors-Functional-info-light, #EDFCFE);
  border-color: var(--Colors-Functional-info, #1EAED0);
}

.collapsibleContentContainer {
  width: 100%;
  margin-top: 4px;
  line-height: 20px;
  text-align: left;
}

.checkboxRoot {
  width: 24px;
  height: 24px;
  background-color: white;
  border: 1.5px solid #AFAC95;
  border-radius: 50%; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
  transition: border-color 0.3s ease;
}

.checkboxRoot[data-state="checked"] {
  border-color: #1EAED0;
}

.checkboxIndicator {
  background-color: #1EAED0;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.checkboxRoot[data-state="unchecked"] .checkboxIndicator {
  display: none;
}

.itemContent {
  display: flex;
  align-items: center;
}

.checkIcon {
  color: white;
}

.profileImage {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 8px;
  background-color: gainsboro;
}