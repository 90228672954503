.container {
  color: #F3F2EB;
  background-color: #FABB00;
  padding: 4px;
  font-weight: bold;
  border-top-left-radius: 26px;
  border-bottom-left-radius: 26px;
  border-top-right-radius: 12px;
  font-size: 20px;
  border-top-right-radius: 12px;
}