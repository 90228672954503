.content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
}

.contentVideo {
  flex: 1;
  width: 100%;
  height: auto;
}

.contentText {
  flex: 1;
  text-align: left;
}

.contentSep {
  height: 1px;
  width: 100%;
  border: none;
  background-color: #DCDCCC;
  margin: 0px;
  margin-bottom: 16px;
}
