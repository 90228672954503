.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.topControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  min-height: 21px;

  :global(.text) {
    font-weight: 600;
  }
}

.topControls :global(.team-name) {
  width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.participantsListContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
  gap: var(--Size-size-11, 11px);
  overflow: hidden;
}

.participantsListContainerSinglePlayer {
  grid-template-columns: repeat(auto-fit, minmax(100px, 160px));
}

.participantContainer {
  border-radius: var(--Size-size-6, 6px);
  border: 1px solid rgba(210, 207, 203, 0);
  position: relative;
  overflow: hidden;
  aspect-ratio: 1 / 1;
}

.participantName {
  color: white;
  border-radius: 4px;
  background: rgba(54, 54, 54, 0.5);
  backdrop-filter: blur(5px);
  padding: 6px 10px;
  text-align: center;

  position: absolute;
  bottom: 6px;
  left: 6px;
  right: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 3;
}

.participantName i {
  position: absolute;
  right: 2px;
  font-size: 10px;
  top: 7px;
}

.individualSectionCoverContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
}

.individualSectionMarker {
  padding: 5px 10px;
  border-radius: var(--br-pill, 9999px);
}

.imageContainer:global(:not(.hidden)), .videoContainer:global(:not(.hidden)) {
  opacity: 1;
  animation: fadeIn 0.3s ease-in;
}

.videoContainer:global(.hidden), .imageContainer:global(.hidden) {
  opacity: 0;
}

.imageContainer div:global(.loader) {
  width: 50px;
  height: 50px;
  border-color: #FFF transparent #FFF transparent;
}

.infoContainer {
  width: 56%;
  text-align: center;
  color: var(--Colors-Neutral-gray-800, #646059);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}