.radioGroupRoot {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.typeSelector {
  display: flex;
  padding: 16px;

  align-items: flex-start;
  gap: 12px;

  border-radius: var(--br-12, 12px);
  border: 1px solid var(--Colors-Neutral-gray-200, #dcdccc);
  cursor: pointer;
}

.typeSelector:has(.radioGroupItem[data-state="checked"]) {
  border: 2px solid var(--Colors-Functional-success-dark, #15a457);
  padding: 15px;
}

.typeSelector i {
  color: white;
  font-size: 12px;
}

.label {
  cursor: pointer;
}

.radioGroupItem {
  box-sizing: border-box;
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: 3px;
  border: 1px solid var(--Colors-Neutral-gray-700, #797466);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.radioGroupItem[data-state="checked"] {
  background-color: var(--Colors-Functional-Success-600, #64eca2);
  border-color: var(--Colors-Functional-Success-600, #64eca2);
}

.radioGroupIndicator {
  display: flex;
}
