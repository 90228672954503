.toastViewport {
  --viewport-padding: 25px;
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: var(--viewport-padding);
  gap: 10px;
  width: 390px;
  max-width: 100vw;
  margin: 0;
  list-style: none;
  z-index: 9999;
  outline: none;
}

.toastContainer {
  background-color: white;
  border-radius: 6px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.toastContainer[data-state="open"] {
  animation: slideIn 350ms cubic-bezier(0.16, 1, 0.3, 1);
}

.toastContainer[data-state="closed"] {
  animation: hide 100ms ease-in;
}

.toastContainer[data-swipe="move"] {
  transform: translateX(var(--radix-toast-swipe-move-x));
}

.toastContainer[data-swipe="cancel"] {
  transform: translateX(0);
  transition: transform 200ms ease-out;
}

.toastContainer[data-swipe="end"] {
  animation: swipeOut 100ms ease-out;
}

.toastContainer:global(.neutral) {
  background: var(--Colors-Neutral-gray-50, #F7F7F3);
}

.toastContainer:global(.info) {
  background: var(--Colors-Cyan-50, #EDFCFE);
}

.toastContainer:global(.success) {
  background: var(--Colors-Functional-success-light, #BAF8D6);
}

.toastContainer:global(.warning) {
  background: var(--Colors-Yellow-50, #FEF9E2);

}

.toastContainer:global(.error) {
  background: var(--Colors-Red-50, #FEF2F3);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  gap: 4px;
  padding: 16px 48px 16px 56px;
}

.toastContainer:global(.neutral) .content {
  color: var(--Colors-Neutral-gray-700, #797466);
}

.toastContainer:global(.info) .content {
  color: var(--Colors-Functional-info, #1EAED0);
}

.toastContainer:global(.success) .content {
  color: var(--Colors-Functional-success-dark, #15A457);
}

.toastContainer:global(.warning) .content {
  color: #CA8A04;
}

.toastContainer:global(.error) .content {
  color: var(--Colors-Functional-danger-dark, #941D3F);
}

.title {
  font-family: "Pally Variable";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}

.description {
  color: inherit;
}

.action {
  position: absolute;
  right: 6px;
  top: 6px;
  padding: 6px;
  display: flex;
  width: 24px;
  height: 24px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

.toastContainer:global(.neutral) .action {
  color: var(--Colors-Neutral-gray-700, #797466);
}

.toastContainer:global(.info) .action {
  color: var(--Colors-Functional-info, #1EAED0);
}

.toastContainer:global(.success) .action {
  color: var(--Colors-Functional-success-dark, #15A457);
}

.toastContainer:global(.warning) .action {
  color: #CA8A04;
}

.toastContainer:global(.error) .action {
  color: var(--Colors-Functional-danger-dark, #941D3F);
}

.iconContainer {
  position: absolute;
  left: 12px;
  top: 16px;
}

.iconContainer i {
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 32px;
  font-style: normal;
  line-height: 20px;
}

.toastContainer:global(.neutral) .iconContainer i {
  color: var(--Colors-Neutral-gray-700, #797466);
}

.toastContainer:global(.info) .iconContainer i {
  color: var(--Colors-Functional-info, #1EAED0);
}

.toastContainer:global(.success) .iconContainer i {
  color: var(--Colors-Functional-success-dark, #15A457);
}

.toastContainer:global(.warning) .iconContainer i {
  color: #CA8A04;
}

.toastContainer:global(.error) .iconContainer i {
  color: var(--Colors-Functional-danger-dark, #941D3F);
}

.duration {
  position: relative;
  display: block;
  float: none;
  clear: both;
  height: 3px;
  width: 100%;
}

.duration::after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: inherit;
  animation-name: maxWidth;
  animation-duration: 3s;
  animation-timing-function: linear;
}

.toastContainer:global(.neutral) .duration {
  background: var(--Colors-Neutral-gray-700, #cecebf);
}

.toastContainer:global(.info) .duration {
  background: var(--Colors-Cyan-50, #b3eef6);
}

.toastContainer:global(.success) .duration {
  background: var(--Colors-Functional-success-light, #7fc9a2fa);
}

.toastContainer:global(.warning) .duration {
  background: var(--Colors-Yellow-50, #f8e693);
}

.toastContainer:global(.error) .duration {
  background: var(--Colors-Red-50, #f3ced1);
}

.toastContainer:global(.neutral) .duration::after {
  background: var(--Colors-Neutral-gray-700, #797466);
}

.toastContainer:global(.info) .duration::after {
  background: var(--Colors-Functional-info, #1EAED0);
}

.toastContainer:global(.success) .duration::after {
  background: var(--Colors-Functional-success-dark, #15A457);
}

.toastContainer:global(.warning) .duration::after {
  background: #CA8A04;
}

.toastContainer:global(.error) .duration::after {
  background: var(--Colors-Functional-danger-dark, #941D3F);
}

@keyframes hide {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slideIn {
  from {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }

  to {
    transform: translateX(0);
  }
}

@keyframes swipeOut {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }

  to {
    transform: translateX(calc(100% + var(--viewport-padding)));
  }
}

@keyframes maxWidth {
  to {
    width: 100%;
  }
}