.description {
  color: #292929;
  font-size: 18px;
  font-weight: 700;
  text-align: start;
  margin-top: 20px;
}

.details {
  display: flex;
  justify-content: space-between;
  border: 1px solid #bfbeb6;
  border-radius: 12px;
  background: #FEF8E1;
  padding: 12px 0;
  width: 100%;
  margin-top: 8px;
}

.details :global(.journey-details-item) {
  border-right: 1px solid #bfbeb6;
}

.details :global(.journey-details-item):last-child {
  border-right: none;
}

.detailsLogo {
  color: #fabb00;
  font-size: 36px;
}
