.content form:global(.form-container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.content form div:global(.form-row) {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.content form label:global(.label) {
  color: var(--Colors-Neutral-gray-700, #444340);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.content form label:global(.label.disabled) {
  color: var(--Colors-Neutral-gray-400, #bebeb3);
}

.content form div:global(.form-row.actions) {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.content form input:global(.FormControl),
.content form .selectInput {
  border: 1px solid #bebeb3;
  background: transparent;
  color: var(--Colors-Text-text-primary, #292929);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  border-radius: 10px;
}

.content form input:global(.FormControl) {
  height: 32px;
}

.content form .selectInput {
  height: 22px;
}

.content form .selectInput:disabled {
  background: var(--Colors-Neutral-gray-200, #edeee6);
  color: var(--Colors-Neutral-gray-400, #bebeb3);
}

.content form input:global(.FormControl):disabled,
.content form .selectInput:disabled {
  background: var(--Colors-Neutral-gray-200, #edeee6);
  color: var(--Colors-Neutral-gray-400, #bebeb3);
}

.selectedTagList {
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
}

.selectedTag {
  display: flex;
  padding: 7px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--br-8, 8px);
  border: 1px solid var(--Colors-Neutral-gray-400, #bebeb3);
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.selectedTag button {
  cursor: pointer;
}

.errorContainer {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-top: 30px;
  color: var(--Colors-Functional-Error-600, #ff6b6b);
  font-weight: 500;
}