div:global(.DialogContent) {
  background-color: #fff;
  overflow: auto;
}

.dialogContent {
  display: inline-flex;
  justify-content: space-between;
  height: auto;
  padding: 32px;
  flex-direction: column;
  gap: 10px;
}

.title {
  color: var(--Colors-Text-text-primary, #292929);
  font-family: "Pally Variable";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  margin-bottom: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 80vh;
}

.textAreaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
}

.textAreaContainer textarea {
  height: 160px;
  padding: 16px;
  background-color: transparent;
  margin-top: 15px;
  color: var(--Colors-Text-text-secondary, #363636);
}

.textAreaContainer textarea::placeholder {
  font-size: 15px;
  color: var(--Colors-Text-text-primary, #363636);
}

.textAreaContainer label {
  color: var(--Colors-Text-text-primary, #363636);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  padding-left: 3px;
}

.dialogFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  gap: 10px;
}

.dialogFooter button:global() {
  border: none;
  cursor: pointer;
  color: var(--Components-Button-Primary-button-primary-text, #363636);
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: var(--br-10, 10px);
  border: 1px solid var(--Components-Button-Outline-button-outline-color, #c6c5b2);
}

.dialogFooter button:global() i {
  margin-right: 3px;
}

.dialogFooter button:global(.btn.dbl.primary.submit) {
  border-radius: var(--br-10, 10px);
  background: var(--Components-Button-Primary-button-primary-color, #fabb00);
  color: white;
}

.dialogFooter button:global(.btn.dbl.primary.submit .front) {
  background: var(--Components-Button-Primary-button-primary-color, #fabb00);
}

.dialogFooter button:global(.btn.dbl.primary.cancel) {
  background-color: white;
}

.dialogFooter button:global(.btn.dbl.primary.cancel .front) {
  background-color: white;
  color: black;
  transform: translateY(-4px) !important;
}

.dialogFooter button:global(.btn.dbl.primary.cancel.disabled .front) {
  transform: translateY(0px) !important;
}

.dialogCloseButton {
  top: 22px;
  right: 20px;
}

.dialogCloseButton svg {
  width: 20px;
  height: 20px;
}