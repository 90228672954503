.resetPasswordContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 48px - 48px);
  border-radius: 16px;
  background: var(--Colors-Neutral-gray-50, #f7f7f3);
}

.resetPasswordContainer input:global(.FormControl) {
  text-align: left;
}

.resetPasswordContainer div:global(.error) {
  color: var(---Colors-Functional-Danger-700, #b91c1c);
}

.resetPasswordContainer div:global(.login-link) {
  color: var(--Colors-Text-text-secondary, #797466);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.resetPasswordContainer div:global(.login-link) a {
  text-decoration-line: underline;
  color: var(--Colors-Text-text-secondary, #797466);
}

.subTitle {
  margin-bottom: 16px;
  line-height: 22px;
}

.formContainer {
  padding-bottom: 28px;
}
