.button {
  display: flex;
  gap: 6px;
}

.ellipsis {
  display: block;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}
