.workshopReviewContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
}

.workshopReviewContainer::-webkit-scrollbar {
  display: none;
}

.sectionCard {
  margin-top: 16px;
}

.headerContainer {
  border-bottom: 2px solid var(--Colors-Neutral-gray-300, #DCDCCC);
  padding-bottom: 16px;
}

.headerContainer h1 {
  width: 100%;
  text-align: left;
  font-size: 28px;
}

.activitiesContainer {
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  gap: 8px;
  overflow-x: auto;
}

.scrollableContainer {
  overflow-x: auto;
}

.scrollableInnerContainer {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: var(--br-12, 12px);
  border: 1px solid var(--Colors-Neutral-gray-200, #DCDCCC);
}

.overviewContent {
  display: flex;
  gap: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--Colors-Neutral-gray-200, #DCDCCC);
}

.overviewContent>div {
  flex: 1;
}

.overviewContent div:global(.activity-container) {
  height: 100%;
  background: none;
}

.overviewContent div:global(.main-container) {
  border-radius: var(--br-xl, 12px);
  height: auto;
}

.overviewContent :global(.drag-and-drop-card-disabled),
.overviewContent :global(.comparison-button-option) {
  cursor: auto;
}

.overviewContent :global(.video-player-container) {
  flex: 0 0 59%;
}