.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 48px - 48px)
}

.inner {
  width: 390px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: hidden;
}

.loaderContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 2px;
}

.loaderContent, .videoContent {
  opacity: 1;
}

.loaderContent:global(.hidden),
.videoContent:global(.hidden) {
  opacity: 0;
  height: 0px;
}

.loaderActions {
  border-radius: 8px;
  border-radius: var(--br-8, 8px);
  display: flex;
  gap: 8px;
  height: 32px;
  justify-content: center;
  padding: 6px;
  position: absolute;
  top: 330px;
  left: calc(195px - 60px);
}

.loaderVideo {
  width: 390px;
  height: 390px;
}

.actionContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.technicalSetupAction {
  color: var(--Components-Button-Link-button-link-text, #1A73E8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;
}

.technicalSetupAction:hover {
  cursor: pointer;
}

@media (max-height: 750px) {
  .loaderActions {
    top: calc(240px - 44px - 16px);
  }

  .loaderVideo {
    max-height: 240px;
  }
}