.container {
  height: calc(100vh - 96px);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
  position: relative;
}

.sessionListContainer {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing--24, 24px);
}

.headerContainer {
  color: var(--Colors-Text-text-primary, #292929);
}

.headerContainer p:global(.late) {
  color: var(--Colors-Functional-Danger-400, #e9667d);
  text-align: center;
}

.content {
  display: flex;
  width: 50vw;
  padding: 24px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}

.sessionListTable {
  width: 100%;
  text-align: left;
}

.sessionListTable thead th {
  color: var(--Colors-Text-text-palest, #9e9a86);
  line-height: 22px;
  padding: 12px 0;
}

.sessionListItem td {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
}

.sessionListItem:last-child td {
  border-bottom: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
}

.sessionListItem td:global(.action) {
  width: 65px;
}

.sessionListItem i:global(.icon) {
  display: flex;
  width: 12px;
  height: 12px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: 140%;
}

.sessionListItem div:global(.players-count) {
  display: inline-flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--br-pill, 9999px);
  border: 1px solid var(--Components-Badge-badge-primary-border, #fecd20);
  background: var(--Components-Badge-badge-primary-color, #fef8e1);
  color: var(--Components-Badge-badge-primary-text, #874b0c);
}

.playersCount {
  color: var(--Components-Badge-badge-primary-text, #874b0c);
}

.sessionListItem div:global(.players-count.almost-full) {
  color: var(--Colors-Functional-Info-700, #0e7490);
  border: 1px solid var(--Components-Badge-badge-info-border, #7cddf0);
  background: var(--Components-Badge-badge-info-color, #d2f5fb);
}

.sessionListItem div:global(.players-count.almost-full) span {
  color: var(--Colors-Functional-Info-700, #0e7490);
}

.sessionListItem div:global(.players-count.full) {
  color: var(--Components-Badge-badge-light-text, #646059);
  border: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.sessionListItem div:global(.players-count.full) span {
  color: var(--Components-Badge-badge-light-text, #646059);
}

.sessionListItem button:global(.join-button):disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.sessionListItem button:global(.join-button) span.xs {
  color: var(--Colors-Text-text-primary, #292929);
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.footerQuestion {
  color: var(--Colors-Neutral-gray-800, #1f1f1e);
  text-align: center;
  font-weight: 600;
}

.footer :global(.email) {
  color: #1a73e8;
  text-decoration: none;
  font-weight: 500;
}

.infoBox {
  position: absolute;
  top: 14px;
}
