.user {
  display: flex;
  align-items: center;
  gap: 8px;
}

.user:global(.inactive) {
  opacity: 0.3;
}

.user div:global(.avatar-container) {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--br-4, 4px);
  background: var(--Colors-Neutral-gray-450, #A3A294);
  position: relative;
}

.user div:global(.avatar-container img) {
  border-radius: var(--br-4, 4px);
}

.imageContainer div:global(.loader) {
  border-width: 2px;
  border-color: white transparent white transparent;
}

.user div:global(.avatar-container .icon) {
  display: flex;
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -4px;
  top: -3px;
  border-radius: var(--br-pill, 9999px);
}

.user div:global(.avatar-container .icon.accepted) {
  background: var(--Colors-Functional-Success-400, #4AAC77);
}

.user div:global(.avatar-container .icon.tentative) {
  background: var(--Colors-Functional-Warning-400, #F6BE30);
}

.user div:global(.avatar-container .icon.declined) {
  background: var(--Colors-Functional-Danger-400, #E9667D);
}

.user div:global(.avatar-container .icon.pending) {
  background: var(--Colors-Neutral-gray-400, #bebeb3);
}

.user div:global(.avatar-container .icon) i {
  font-size: 7px;
  color: var(--Colors-Functional-Mono-white, #FFF);
}

.username {
  color: var(--Colors-Text-text-primary, #292929);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}