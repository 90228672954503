.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 18px;
  height: 100%;
  text-align: left;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
}

.viewResultsContainer {
  padding-top: 24px !important;
  background-color: transparent !important;
  border: 1px solid var(--Colors-Neutral-gray-200, #DCDCCC);
  padding: 24px;
  border-radius: 12px;
  gap: 16px;
  overflow: auto;
}

.viewResultsContainer .iframeContainer {
  align-items: center;
  justify-content: center;
}