.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1 1 auto;
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

.container> :global(.main-container) {
  flex: 1;
  align-items: center;
}

.container> :global(.main-container.row) {
  flex: 0;
}

.emotionMainContainer {
  justify-content: center;
}

.emotionsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.emotionsContainer:global(.endEmotion) {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: auto;
}

.emotionContent {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.emotionContent p:global(.text) {
  width: 60%;
}

.emotionListContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  gap: 8px;
}

.infoText {
  margin-top: 8px;
}