.dialogTitle {
  margin-bottom: 24px !important;
  color: var(--Colors-Text-text-primary, #363636);
  font-family: "Pally Variable";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}

.dialogContent {
  color: var(--Colors-Text-text-primary, #363636);
  padding: var(--br-16, 16px) 0;
}

.dialogFooter {
  display: flex;
  margin-top: 25px;
  gap: 16px;
  justify-content: flex-end;
}

.dialogCloseButton {
  top: 23px;
  right: 20px;
}

.dialogCloseButton svg {
  width: 20px;
  height: 20px;
}