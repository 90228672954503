.container {
  display: flex;
  gap: var(--Size-size-88, 88px);
  text-align: left;
  flex-direction: row;
  height: 100%;
  border-radius: var(--br-xl, 12px);
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contentWrapper {
  display: flex;
  gap: 23.5px;
  align-self: stretch;
  height: 100%;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
}

.line {
  width: 1px;
  background: var(--Colors-Neutral-gray-200, #dcdccc);
}

.sharedValuesContainer {
  min-width: 330px;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing--16, 16px);
}

.dragAndDropContainer :global(.drag-and-drop-list) {
  padding-top: 0px !important;
}

.iconContainer {
  background-color: transparent;
  font-size: 20px;
}
