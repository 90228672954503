.sidePanelContainer {
  flex-basis: 30%;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 12px;

  display: flex;
  flex-direction: column;
  position: relative;
}

.sidePanelContainer div:global(.side-panel-final-screen) {
  max-width: 100%;
}

.sidePanelContainer:global(.side-panel-final-screen) .content {
  height: calc(100vh - 80px - 232px);
}

.container {
  height: 100%;
  box-sizing: border-box;
}

.containerWithLeaderBoard {
  height: calc(100% - 77px);
}

.content {
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  position: relative;
}

.content::after {
  content: "";
  position: sticky;
  display: block;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 66px;
  background: linear-gradient(to bottom, rgba(247, 247, 242, 0) 0%, rgba(247, 247, 242, 1) 100%);
  pointer-events: none;
}

.topControls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  :global(.text) {
    font-weight: 600;
  }
}

.participantsContainer {
  text-align: left;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: var(--Size-size-32, 32px);
}

.controlsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deviceControls {
  display: flex;
  gap: 8px;
}

.deviceControls button:global(.btn) {
  width: 16px;
  padding: 0px !important;
  height: auto;
}

.deviceControls button:global(.btn):active {
  box-shadow: none;
}

.deviceControls button:global(.device-muted) {
  color: var(--Colors-Functional-Danger-400, #e9667d);
}

.deviceControl {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}

.deviceControl:global(.deactivated button) {
  opacity: 1;
}

.deviceControl:global(.deactivated i),
.deviceControl:global(.deactivated svg) {
  color: var(--Colors-Neutral-gray-300, #dcdccc);
}

.deviceControl:global(:not(.deactivated)):hover {
  background-color: var(--Colors-Neutral-gray-300, #dcdccc);
  transition: 0.3s ease-in;
  border-radius: var(--br-8, 8px);
}

.deviceControl:global(:not(.deactivated)) i:hover {
  transform: scale(1.1);
  transition: 0.2s;
  animation: ease-in-out;
}

.deviceControl:global(:not(.deactivated)) button:hover {
  transform: scale(1.1);
  transition: 0.2s;
  animation: ease-in-out;
  background-color: var(--Colors-Neutral-gray-300, #dcdccc);
}

.deviceControl button:global(.secondary) {
  margin-right: 3px;
}

.deviceControl button:global(.secondary):active {
  margin-right: 3px;
  box-shadow: none;
}

.deviceControl button:global(.secondary):hover {
  color: var(--Colors-Text-text-secondary, #797466);
}

.deviceControl:not(:hover) {
  background-color: transparent;
  border-radius: var(--br-8, 8px);
  transition: 0.5s ease-out;
}

.deviceControlIcon {
  font-size: 16px;
}

.tooltipContainer {
  max-width: 250px;
  margin-right: 17px;
  text-align: left;
}