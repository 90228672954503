.container {
  display: flex;
  gap: var(--br-24, 24px);
  text-align: left;
  border-top-left-radius: 0%;
  border-top-right-radius: 0%;
  padding-bottom: 25px;
}

.viewResultsContainer {
  padding: 0px !important;
  background-color: transparent !important;
  gap: 32px !important;
}

.answersDataContainer,
.answersContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--Size-size-8, 8px);
  padding-left: 20px;
  margin-left: -20px;
  padding-right: 20px;
  margin-right: -20px;
  padding-bottom: 24px;
}

.answersContainer:global(.horizontal) {
  flex-direction: row;
  margin-top: 8px;
}

.answersContainer:global(.horizontal) > * {
  flex: 1;
}

.answersDataContainer {
  gap: var(--Size-size-16, 16px);
}

.viewResultsContainer .answersDataContainer {
  height: auto;
  overflow: auto;
  margin-left: 0px;
  margin-right: 0px;
}

.answerExplanation {
  height: 0;
  overflow: hidden;
}

.answerExplanation:global(.expanded) {
  height: auto;
  padding-bottom: 1px;
}

.instructions {
  display: inline;
}
