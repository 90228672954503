.workshopContent {
  display: flex;
  width: 794px;
  padding: 40px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: var(--br-8, 8px);
  background: var(--Colors-Neutral-gray-200, #EDEEE6);
}

.workshopContent>div {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.textInfo {
  display: flex;
  align-items: center;
  gap: 6px;
}

.invitedUsers {
  display: flex;
  padding-top: 8px;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
}

.actions {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.workshopBtn {
  display: flex;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: var(--br-10, 10px);
  border: 1px solid var(--Components-Button-Primary-button-primary-border, #F6BE30);
  background: var(--Components-Button-Primary-button-primary-color, #F6BE30);
  text-decoration: none;
  color: var(--Components-Button-Primary-button-primary-text, #444340);

  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
}

.rescheduleBtn {
  display: flex;
  padding: 12px 14px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: var(--br-10, 10px);
  border: 1px solid var(--Components-Button-Outline-button-outline-color, #DCDCCC);
  background: var(--Colors-Main-transparent, rgba(255, 255, 255, 0.01));
  cursor: pointer;
}

.rescheduleBtn:disabled {
  cursor: not-allowed;
}