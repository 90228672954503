.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.actions {
  position: absolute;
  height: calc(44px - 12px);
  top: calc(390px - 44px - 16px);
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--br-8, 8px);
  background: var(--Colors-Neutral-gray-200, #f7f7f2);
  z-index: 3;
}

.action {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 6px;
}

.action button:global(.video-btn) {
  margin-right: 3px;
}

.action i:global(.icon) {
  width: 16px;
  cursor: pointer;
}

.actions .action:disabled button {
  color: var(--Colors-Neutral-gray-300, #dcdccc);
  cursor: not-allowed;
}

.action:hover {
  background-color: var(--Colors-Neutral-gray-300, #dcdccc);
  transition: 0.3s;
  border-radius: var(--br-8, 8px);
}

.action:not(:hover) {
  background-color: transparent;
  border-radius: var(--br-8, 8px);
  transition: 0.5s ease-out;
}

.actions button:hover i {
  transform: scale(1.2);
  transition: 0.2s;
  animation: ease-in-out;
}

.actions button:global(.deactivated) {
  color: var(--Colors-Functional-Danger, #d12d52);
}

.intellectualPropertyAlert {
  padding: 16px;
  gap: 16px;
  display: flex;
  border-radius: 12px;
  border: 1px solid var(--Colors-Neutral-gray-300, #ebeadf);
  background: var(--Colors-Functional-Mono-white, #fff);
}

.intellectualPropertyAlertData {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.mutedVideoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  background-color: var(--Colors-Neutral-gray-200, #edeee6);
  border-radius: var(--br-16, 14px);
  z-index: 3;
  top: -1px;
}

.mutedVideoContainer i {
  font-size: 285px;
}

.description {
  margin-top: 8px;
  display: block;
}

@media (max-height: 750px) {
  .actions {
    top: calc(240px - 44px - 16px);
  }

  .mutedVideoContainer {
    max-height: 240px;
  }

  .mutedVideoContainer i {
    font-size: 200px;
  }
}

@media (max-height: 650px) {
  .actions {
    top: calc(240px - 44px - 52px);
  }
}
