.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.iconContainer i {
  font-size: 10px;
  color: white;
}

.iconContainer:global(.accepted) {
  background-color: var(--Colors-Functional-Success-400, #4AAC77);
}

.iconContainer:global(.tentative) {
  background-color: var(--Colors-Functional-Warning-400, #F6BE30);
}

.iconContainer:global(.declined) {
  background-color: var(--Colors-Functional-Danger-400, #E9667D);
}

.iconContainer:global(.pending) {
  background-color: var(--Colors-Neutral-gray-400, #bebeb3);
}
.iconContainer:global(.auto_generated) {
  background-color: var(--Colors-Functional-Success-400, #4AAC77);
}