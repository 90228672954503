.container :global(.video-player-container) {
  min-height: 300px;
  margin-bottom: 16px;
}

.questionDetails {
  padding: 24px 32px;
  background-color: white;
  text-align: left;
  color: #292929;
  border-radius: var(--br-12, 12px);
}

.collapsibleTrigger {
  width: 100%;
}

.questionInstructions {
  margin-bottom: 16px;
  text-align: left;
  line-height: 26px;
  font-weight: 500;
}

.questionTitle {
  margin-bottom: 16px;
  line-height: 26px;
  font-size: 17px;
  font-weight: 700;
  display: flex;
  font-family: "Inter";
}

.collapsibleContainer {
  width: 100%;
  overflow: hidden;
}

.collapsibleContentContainer[data-state="open"] {
  animation: slideDown 300ms ease-out;
}
.collapsibleContentContainer[data-state="closed"] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.questionTitle::before {
  font: var(--fa-font-solid);
  content: "\e1d8";
  font-size: 24px;
  margin-right: 8px;
}

.questionDescription * {
  font-size: 16px;
  line-height: 26px;
}

.questionDescription ul {
  list-style: initial;
  padding-left: 20px;
}

.questionDescriptionTitle {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.questionDescription:global(.collapsible) {
  margin-top: 16px;
}

.questionDescriptionTitleChevron {
  top: -2px;
}
