.container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
  text-align: left;
  height: 100%;
  overflow: auto;
}

.container h3 {
  padding-left: 10px;
}

.headingText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headingText h3 {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
}

.journeysListBody {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.trainingMethodologies {
  width: 100%;
  height: 714px;
  margin-top: 50px;
  padding-bottom: 26px;
  border-radius: 12px;
  background: #f7f7f3;
}

.trainingMethodologies h2 {
  font-size: 22px;
  text-align: center;
  padding: 35px 10%;
}

.trainings {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  padding: 16px;
}

.requestBtn {
  display: block;
  color: #1f1f23;
  margin: 12px auto;
}

.trainings .trainingPlaceholder {
  width: 100%;
  height: 160px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #d9d9d9;
}

.skeletons {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.skeletons .upper {
  display: flex;
  flex-direction: column;
}
