.container {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 16px var(--spacing--8, 8px);
  background: var(--Colors-Neutral-gray-100, #F7F7F3);
  width: 250px;
  border-radius: var(--br-12, 12px);
}

.group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.groupTitle {
  padding: 7px 12px;
}

.divider {
  margin: 16px 0;
  background: var(--Colors-Neutral-gray-200, #EDEEE6);
  width: 100%;
  height: 1px;
}

.link {
  display: flex;
  text-decoration: none;
  padding: 10px 12px;
  gap: 8px;
  border-radius: var(--br-8, 8px);
}

.link:global(.active):not(.disabled) {
  background: var(--Colors-Brand-brand-primary, #F6BE30);
}

.link:hover {
  /* TODO Real variable for this */
  background: rgba(246, 190, 48, .2);
}

.link.disabled {
  color: var(--Colors-Text-text-faded, #918E7E);
  cursor: not-allowed;
}

.link.disabled:hover {
  /* TODO Real variable for this */
  background-color: rgba(145, 142, 126, .2);
}

.link.disabled span:global(.text),
.link.disabled i {
  opacity: .7;
}

.link .icon {
  flex: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}

.link :global(.text) {
  flex: 1;
  display: flex;
  align-items: center;
}