.journeyCardContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
}

.journeyCardContainer:global(.completed),
.journeyCardContainer:global(.ongoing) {
  padding: 20px 16px;
  border-radius: var(--br-12, 12px);
}

.journeyCardContainer:global(.ongoing) {
  border: 1px solid #FECD20;
  background: #FEF8E1;
}

.journeyCardContainer:global(.completed) {
  border: 1px solid var(--Colors-Green-400, #49DF8E);
  background: var(--Colors-Green-50, #F0FDF5);
}

.iconContainer {
  display: flex;
  justify-content: center;
}

.iconContainerInner:global(.locked) {
  border-radius: var(--br-pill, 9999px);
  border: 1px solid #AFAC95;
}

.iconContainer i {
  display: flex;
  width: 12px;
  height: 12px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  padding: 16.4px;
  border-radius: var(--br-pill, 9999px);
  color: white;
}

.journeyCardContainer:global(.completed) .iconContainer i {
  background: var(--Colors-Green-400, #49DF8E);
}

.journeyCardContainer:global(.ongoing) .iconContainer i {
  background: var(--Colors-Yellow-400, #FECD20);
}

.journeyCardContainer:global(.locked) .iconContainer {

  border: 1px solid #AFAC95;
  border-radius: 50%;
  padding: 8px;
}

.journeyCardContainer:global(.locked) .iconContainer i {
  color: var(--Colors-Neutral-gray-400, #AFAC95);
}

.content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
}

.headerText {
  display: flex;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.headerText :global(h3) {
  min-height: 32px;
  display: flex;
  align-items: center;
}

.journeyDate {
  border-radius: 8px;
  padding: 6.2px 14px;
  height: 18px;
  position: absolute;
  right: 16px;
  top: 20px;
}

.journeyCardContainer:global(.completed) .journeyDate {
  border: 1px solid #49DF8E;
  background: var(--Colors-Green-50, #F0FDF5);
}

.journeyCardContainer:global(.ongoing) .journeyDate {
  border: 1px solid #FECD20;
  background: #FEF8E1;
}

.journeyDate span {
  color: #1F1F23;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  opacity: 0.7;
}

.footerContent {
  display: flex;
  justify-content: space-between;
}

.footerInfo {
  display: flex;
  gap: 15px;
}

.delimiter {
  height: 32px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid rgba(73, 223, 142, 0.50);
  background: #F7F7F3;
}

.sectionTypes {
  display: flex;
  gap: 16px;
}

.sectionTypes img {
  width: 28px;
  height: 32px;
}

.ahaMoments {}

.behaviours {}

.ahaMoments, .behaviours {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actions {}

.actionButton {}