.container {
  display: flex;
  gap: 20px;
  height: calc(100vh - 80px);
  overflow: hidden;
}

.mainSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}
