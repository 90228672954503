.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  position: relative;
  top: 5%;
}

.container .imageContainer {
  display: flex;
  width: 694px;
  flex-direction: column;
  align-items: center;
}

.container .textContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  width: 700px;
}

.container .redirectBtn {
  width: fit-content;
  height: auto;
  margin: 0 auto;
  padding: 12px 14px;
  margin-top: 6px;
  font-size: 16px;
}

.imageContainer img {
  height: 330px;
  width: 300px;
}
