:global(.driver-popover).popover {
  padding: 16px;
  color: var(--Colors-Neutral-gray-900, #292929);
  border-radius: 16px;
  min-width: 290px;
}

:global(.driver-popover .driver-popover-arrow.driver-popover-arrow-align-end) {
  right: 50%;
  margin-right: -5px;
}

.popover .popoverTitle {
  font-size: 16px;
  font-weight: 600;
  font-family: Inter;
}

.popover :global(.driver-popover-close-btn) {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Colors-Text-text-palest, #9E9A86);
  font-weight: 300;
  font-size: 26px;
  top: 11px;
  right: 6px;
}

.popover .popoverDescription {
  font-family: Inter;
}

.popover .popoverDescription :global(.description-inner) {
  font-family: Inter;
  line-height: 20px;
  margin-top: 10px;
}

.footerButtons {
  display: flex;
  flex: 1;
  margin-top: 8px;
}
.singleButton {
  justify-content: flex-end;
}

.twoButtons {
  justify-content: space-between;
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.closeBtn:hover .closeBtnIcon {
  color: #59574f;
}

.closeBtnIcon:global(.fa) {
  font-size: 20px;
  font-weight: 300;
  color: var(--Colors-Text-text-palest, #9E9A86);
}