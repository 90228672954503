.dragAndDropList {
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: var(--Size-size-16, 16px);
  max-width: 100%;
  min-height: calc(100% - 68px);
}

.borderHighlight {
  border-radius: var(--br-8, 8px);
  border-top: 1px dashed var(--Colors-Neutral-gray-400, #AFAC95);
  border-right: 1px dashed var(--Colors-Neutral-gray-400, #AFAC95);
  border-left: 1px dashed var(--Colors-Neutral-gray-400, #AFAC95);
  border-bottom: 1px dashed var(--Colors-Neutral-gray-400, #AFAC95);
}

.noPadding {
  padding-top: 0px;
}

.dragAndDropList :global(.drag-and-drop-text) {
  margin-top: 100px;
  top: 100px;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--Colors-Text-text-secondary, #797466);

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}