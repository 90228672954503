.copyright {
  line-height: 20px;
  position: absolute;
  bottom: 8px;
}


@media (max-height: 900px) {
  .copyright {
    right: 24px;
  }
}