.detailsWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  border: 1px solid #BFBEB6;
  border-radius: 12px;
  background-color: #F7F7F3;
  padding: 12px 0;
}

.detailsWrapper :global(.journey-details-item) {
  border-right: 1px solid #BFBEB6;
}

.detailsWrapper :global(.journey-details-item):last-child {
  border-right: none;
}

.detailsLogo {
  color: #FABB00;
  font-size: 36px;
}