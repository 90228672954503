.dragAndDropContainer {
  display: flex;
  align-items: flex-start;
  position: relative;
  border-radius: var(--br-10, 10px);
  background-color: white;
  border: 1.5px solid var(--Colors-Neutral-gray-400, #BEBEB3);
  transition: 0.3s;
  font-size: 14px;
  color: var(--Colors-Text-text-primary, #292929);
  font-weight: bold;
  height: unset;
  gap: var(--Size-size-4, 4px);

  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dragAndDropContainer:global(:not(.disable)):hover {
  border-color: var(--Colors-Brand-brand-primary, #FABB00);
}

.dragAndDropContainer :global(.drag-and-drop-card-disabled) {
  cursor: not-allowed;
}

.dragAndDropCardContent {
  width: calc(100% - 22px);
  text-align: left;
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.dragAndDropCardContent:global(.draggable) {
  cursor: grab;
}

.dragAndDropCardContent:global(.with-draggable-icon) {
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 12px 16px 12px 8px;
}

.dragAndDropCardContent:global(.with-draggable-icon .draggable-icon) {
  justify-content: center;
  display: flex;
  width: 16px;
  height: 16px;
  color: var(--Colors-Neutral-gray-400, #AFAC95);
  text-align: center;
  font-family: "Font Awesome 6 Pro";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
}

.dragAndDropCardContent:global(.disabled) {
  cursor: no-drop;
}

.dragAndDropContent {
  display: flex;
  flex-direction: column;
}

.dragAndDropContent:global(.not-expandable) {
  gap: 4px;
}

.dragAndDropTopLine {
  display: flex;
  align-items: center;
  gap: 8px;
}

.dragAndDropExplanation {
  height: 0;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-wrap;
}

.dragAndDropExplanation:global(.expanded) {
  height: auto;
}