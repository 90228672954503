.reactDatePickerWrapper {
  box-sizing: border-box;
  padding: 12.5px 16px !important;
  font-size: 15px;
  line-height: 21px;
  border: 1px solid var(--Colors-Neutral-gray-200, #dcdccc);
  border-radius: var(--br-12, 12px);
  width: 100%;
}

.reactDatePickerWrapper:hover {
  border-color: #b6b6aa;
}

.clearIcon {
  right: 35px !important;
}

.clearIcon::after {
  background-color: #797466 !important;
  padding: 0px !important;
  line-height: 1.2 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  width: 16px;
  height: 16px;
}

.iconClassName {
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  color: #797466;
  cursor: pointer;
}

.iconClassName:hover {
  color: #5f5b4f;
}

.iconClassName:hover+.reactDatePickerWrapper {
  border-color: #b6b6aa;
}

.participantsInput:disabled {
  background-color: var(--Colors-Neutral-gray-100, #edeee6);
}