.container {
  position: relative;
  overflow: hidden;
  margin-top: 40px;
  background-color: #FFECB9;
  border-radius: 12px;
  display: flex;
  justify-content: space-around;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 236, 185, 1) 0%,   
    rgba(255, 236, 185, 0) 21%,  
    rgba(255, 236, 185, 0) 74%,  
    rgba(255, 236, 185, 1) 100%  
  );
}

.buttonsContainer {
  display: flex;
  align-items: center;
  gap: 24px;
}