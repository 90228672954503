.leaderBoardContainer {
  flex: 0 0 45px;
  padding: 16px;
}

.leaderBoardStatsContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.leaderBoardStatsContainer :global(.separator) {
  flex: 0 0 1px;
  height: 35px;
  background-color: var(--Colors-Neutral-gray-300, #DCDCCC);
}

.leaderBoardStat {
  flex: 1;
  display: flex;
  gap: 10px;
  align-items: center;
  flex: 0 0 113px;
}

.leaderBoardStatIcon {
  color: var(--Colors-Brand-brand-primary, #F6BE30);
  font-size: 25px;
  font-style: normal;
  font-weight: 900;
  line-height: 113%;
}

.leaderBoardStats {
  font-family: "Pally Variable";
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.leaderBoardStatLabel {
  color: var(--Colors-Text-text-secondary, #797466);
  text-align: left;
}
