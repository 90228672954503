.ChangePasswordModal {
  display: flex;
  width: 552px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: var(--br-16, 16px);
  background: var(--Colors-Functional-Mono-white, #FFF);
  box-shadow: 0px 8px 8px -4px rgba(17, 17, 20, 0.03);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.content form:global(.form-container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.content form div:global(.FormField) {
  width: 100%;
}

.content form div:global(.form-row) {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  position: relative;
}

.content form label:global(.label) {
  color: var(--Colors-Neutral-gray-700, #444340);
  /* Elements/Caption M */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.content form label:global(.label.disabled) {
  color: var(--Colors-Neutral-gray-400, #BEBEB3);
}

.content form div:global(.icon-container) {
  position: absolute;
  right: 16px;
  top: 50%;
  cursor: pointer;
}

.content form input:global(.FormControl) {
  border: 1px solid #BEBEB3;
  background: transparent;
  color: var(--Colors-Text-text-primary, #292929);
  /* Elements/Input M */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}