.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  height: 85vh;
}

.container .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.content .emoji {
  color: #000;
  text-align: center;
  font-family: "Pally Variable";
  font-size: 100px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
}

.content .descriptionText {
  color: var(--Colors-Text-text-secondary, #797466);
  text-align: center;
  font-feature-settings: "clig"off, "liga"off;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.026px;
}

.content .actions {
  margin-top: 32px;
}
