/** Activity Timer **/
.container:global(.activity-timer) {
  position: relative;
  padding-top: var(--br-4xl, 24px);
  padding-left: var(--br-4xl, 24px);
  width: calc(100% - var(--br-4xl, 24px));
  background: var(--Colors-Neutral-gray-100, #f7f7f3);

  display: flex;
  flex-direction: column;
  gap: var(--br-2xl, 16px);
  z-index: 12;
  border-top-left-radius: var(--br-xl, 12px);
  border-top-right-radius: var(--br-xl, 12px);
}

.infoContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding-right: 18px;
}

.timerTitle {
  color: var(--Colors-Text-text-primary, #292929);
  font-family: "Pally Variable";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timerValue {
  display: flex;
  gap: 8px;
  align-items: center;
}

.info {
  width: 40px;
  text-align: right;
}

.time {
  min-width: 85px;
  text-align: right;
}

.time:global(.isRed) {
  color: var(--Colors-Functional-Error-600, #ff6b6b);
}

.time :global(.time-left) {
  display: flex;
}

.time :global(.time-left > span) {
  min-width: 35px;
  display: inline-block;
  text-align: left;
}


.timeIsUp {
  background: var(--Colors-Functional-Danger-400, #E9667D);
  border-radius: var(--spacing--sm, 8px);
  color: var(--Colors-Functional-Mono-white, #fff);
  padding: 3.5px 12.5px 3.5px 12.5px;
}

.hurryUpContainer {
  position: absolute;
  border-radius: var(--br-16, 16px);
  background: var(--Colors-Functional-Mono-white, #fff);
  display: flex;
  padding: 16px;

  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  flex: 1 0 0;
  top: 42px;
  right: 5px;
  width: 335px;
  box-shadow: 0px 12px 16px -4px rgba(17, 17, 20, 0.08), 0px 4px 6px -2px rgba(0, 0, 0, 0.04);
  z-index: 11;
}

.hurryUpContainer :global(.line) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hurryUpContainer :global(.left) :global(.icon) {
  margin-right: 8px;
  font-size: 20px;
}

.hurryUpContainer :global(.right) :global(.icon) {
  color: var(--Colors-Text-text-palest, #BEBEB3);
  cursor: pointer;
}

.hurryUpContainer :global(.info-text) {
  text-align: left;
}

.tooltipArrow {
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  transform: translateY(-50%);
  Left: -8px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 8px solid #fff;
}

.barContainer {
  border-radius: var(--br-pill, 9999px);
  background: var(--Colors-Neutral-gray-300, #dcdccc);
  position: relative;
  height: 2px;
  width: 98%;
  margin-bottom: 8px;
}

.bar {
  height: 2px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--Colors-Functional-Info-600, #22b5d1);
  border-radius: var(--br-pill, 9999px);
}

.bar:global(.isRed) {
  background-color: var(--Colors-Functional-Error-600, #ff6b6b);
}

/** Header Timer **/
.container:global(.timer-container) {
  display: flex;
  top: 18px;
  left: 131px;
  gap: 16px;
}
