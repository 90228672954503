.header {
  height: 32px;
  margin-bottom: 16px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.theme {
  display: flex;
  align-items: center;
}

.theme span {
  margin-left: 20px;
}

.themeLogoSpace {
  width: fit-content;
  position: relative;
  min-width: 56px;
  flex-shrink: 0;
  margin-right: 14px;
  margin-top: 1px;
}

.themeLogo :global(.fetch-image) {
  display: block;
  transition: 0.3s;
  width: auto;
  height: auto;
}

.header:global(.logoBig) {
  height: 48px;
}

.left {
  display: flex;
  transition: 0.3s;
  align-items: center;
}

.left a img {
  height: 27px;
  transition: 0.3s;
  margin-right: 16px;
}

.logo {
  cursor: pointer;
}

#headerSeparator {
  background-color: var(--Colors-Neutral-gray-300, #dcdccc);
  width: 1px;
  height: 30px;
  margin-right: 16px;
}

.headerOutletContainer {
  margin-left: 15px;
}

.header:global(.logoBig) .left {
  justify-content: center;
  width: 100%;
}

.header:global(.logoBig) .left img {
  height: 48px;
}

.right {
  display: flex;
  gap: 4px;
}

.title {
  text-align: left;
  line-height: 1.1;
}

.link {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #90877D;
  padding: 0 10px;
  display: flex;
  align-items: center;
}

.headerButton {
  display: flex;
  padding: 9px var(--Size-size-10, 10px);
  justify-content: center;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  height: 14px;
  border-radius: 6px;
  border: 1px solid var(--Components-Button-Secondary-button-secondary-border, #F7F7F2);
  background: var(--Components-Button-Secondary-button-secondary-color, #F7F7F2);
  color: #90877D;
}

.headerButton:global(.exit) {
  color: #881313;
  border: 1px solid #FECACA;
  background: #FECACA;
  text-decoration: none;
  cursor: pointer;
}

.userProfile {
  position: relative;
  cursor: pointer;
}

.backdrop {
  position: fixed;
  cursor: default;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.userDropdownContainer {
  position: absolute;
  top: 40px;
  right: 0;

  display: flex;
  width: 302px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 8px;
  background: var(--Colors-Functional-Mono-white, #FFF);
  box-shadow: 0px 10px 24px -4px rgba(17, 17, 20, 0.08), 0px 2px 8px -4px rgba(17, 17, 20, 0.03);

  z-index: 20;
}

.userDropdownContainer li:global(.dropdown-item) {
  display: flex;
  height: 40px;
  padding: 10px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--br-8, 8px);
}

.userDropdownContainer span:global(.dropdown-icon) i {
  color: var(--Colors-Text-text-primary, #292929);
}

.userDropdownContainer span:global(.dropdown-text) {
  color: var(--Colors-Text-text-primary, #292929);
  /* Content/Small Bold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 21px */
}