.headerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 16px;
}

.headerContainer h1 {
  width: 100%;
  text-align: left;
  font-size: 28px;
}

.headerContainer img {
  width: 36px;
  height: 36px;
}