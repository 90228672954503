.button {
  min-width: 13px;
  padding: 16px 24px;
  border: 2px solid var(--Colors-Neutral-gray-300, #C6C5B2);
  border-radius: var(--br-8, 8px);
  background-color: var(--Colors-Neutral-gray-50, #F7F7F3);
  cursor: pointer;
  font-weight: 600;
  overflow: hidden;
}

.button:not(.selected):hover {
  border-color: var(--Colors-Neutral-gray-400, #AFAC95);
}

.button:active {
  border-color: var(--Colors-Neutral-gray-300, #C6C5B2);
}

.selected {
  background-color: var(--Colors-Cyan-500, #1EAED0);
  border-color: var(--Colors-Cyan-500, #1EAED0);
  color: white;
}