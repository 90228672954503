.invitationContent {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.invitationContent div:global(.invitation-status) {
  display: flex;
  width: 145px;
  align-items: center;
  gap: 8px;
}

.invitationContent div:global(.invitation-status h5) {
  color: var(--Colors-Text-text-secondary, #797466);

  font-family: "Pally Variable";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}

.invitationContent div:global(.invited-users) {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  flex: 1 0 0;
  flex-wrap: wrap;
}

.spacer {
  height: 1px;
  align-self: stretch;
  background: var(--Colors-Neutral-gray-200, #EDEEE6);
}