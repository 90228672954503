.videoContainer {
  background: lightgray 50% / cover no-repeat;
  position: relative;
  overflow: hidden;
}

.videoElement {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scaleX(-1);
  max-width: none;
  max-height: none;
  z-index: 2;
}

.loaderContainer {
  width: 390px;
  height: 390px;
  position: absolute;
  animation: fadeIn 0.3s ease-in;
  z-index: 999;
  pointer-events: none;
}

.loaderContainer div:global(.loader) {
  width: 50px;
  height: 50px;
  border-color: #fff transparent #fff transparent;
}

@media (max-height: 750px) {
  .videoContainer {
    max-height: 240px !important;
  }

  .loaderContainer {
    max-height: 240px !important;
  }
}

@media (max-height: 650px) {
  .videoContainer {
    height: 200px !important;
  }

  .loaderVideo {
    max-height: 240px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.25;
  }

  50% {
    opacity: 0.5;
  }

  75% {
    opacity: 0.75;
  }

  100% {
    opacity: 1;
  }
}
