
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 28px;
  border-right: 1px solid #BFBEB6;
}

.locked {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(115, 115, 115, 0.7) 0%, rgba(217, 217, 217, 0.1) 60%, rgba(255, 255, 255, 0) 50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}


.container:last-child {
  border-right: none;
}

.inner {
  width: 63%;
  display: flex;
  justify-content: center;
  align-self: center;
}

.title {
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}

.badge {
  position: relative;
}

.badgeLogo {
  width: 100%;
}

.content {
  position: absolute;
  height: 74%;
  top: 10px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #1F1F23;
}

.topContent,
.bottomContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.companyName,
.description {
  font-size: 13px;
  font-weight: 500;
  line-height: 18.4px;
}

.description {
  margin-top: 12px;
}

.companyName {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.companyNameIcon {
  width: 16px;
  height: 16px;
  background-color: #C6EEF2;
}

.companyNameIcon :global(.icon) {
  font-size: 8px;
  color: #0E7490;
}

.separator {
  width: 23px;
  height: 1px;
  background-color: #1F1F23;
  opacity: 0.2;
  margin-top: 12px;
  align-self: center;
}

.achievementName {
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logoText {
  font-size: 8px;
  line-height: 18px;
  margin-top: 13px;
}

.logo {
  max-width: 57px;
  align-self: center;
}

