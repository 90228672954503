@font-face {
  font-family: "Inter";
  src: url("/assets/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf") format("truetype");
}

@font-face {
  font-family: "Pally Variable";
  src: url("/assets/fonts/Pally_Complete/Fonts/Variable/Pally-Variable.ttf") format("truetype");
}

@font-face {
  font-family: "PP Neue Montreal";
  src: url("/assets/fonts/PP_Neue_Montreal/PPNeueMontreal-Bold.otf") format("truetype");
}

/* overrides */

.grecaptcha-badge {
  display: none  !important;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: "Inter";
  padding: 16px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Pally Variable";
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1 {
  color: var(--Colors-Text-text-primary, #292929);

  /* Headline/H1 */
  font-family: "Pally Variable";
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 46.2px */
}

h1.big {
  font-family: "Pally Variable";
  font-size: 70px;
  font-weight: 500;
  line-height: 120%;
  /* 84px */
}

h2 {
  color: var(--Colors-Text-text-primary, #292929);

  /* Headline/H2 */
  font-family: "Pally Variable";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 24.2px */
}

h2.thin,
h3.thin {
  /* Headline/H2-thiner */
  font-weight: 500;
  line-height: 130%;
  /* 28.6px */
}

h3.bold {
  font-weight: 700;
}

h3 {
  color: var(--Colors-Text-text-primary, #292929);

  /* Headline/H3 */
  font-family: "Pally Variable";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
  /* 17.6px */
}

.bold {
  font-weight: bold;
}

h4 {
  color: var(--Colors-Text-text-primary, #292929);

  /* Headline/H4 */
  font-family: "Pally Variable";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  /* 14.4px */
}

h5 {
  color: var(--Colors-Text-text-primary, #292929);

  /* Headline/H5 */
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 125% */
}

h6 {
  color: var(--Colors-Text-text-primary, #292929);
  font-feature-settings: "clig"off, "liga"off;

  /* Headline/H6 */
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
  letter-spacing: 0.03px;
}

.text-subtitle {
  color: var(--Colors-Text-text-primary, #292929);
  font-feature-settings: "clig"off, "liga"off;

  /* Headline/Subtitle */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  /* 150% */
  text-transform: uppercase;
}

.text-overline {
  color: var(--Colors-Text-text-primary, #292929);
  font-feature-settings: "clig"off, "liga"off;

  /* Headline/Overline */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  /* 142.857% */
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.text-body-leading {
  color: var(--Colors-Text-text-primary, #292929);
  font-feature-settings: "clig"off, "liga"off;

  /* Content/Body leading */
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* 133.333% */
  letter-spacing: 0.027px;
}

.text {
  color: var(--Colors-Text-text-primary, #292929);

  /* Content/Body */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}

.text.bold {
  font-weight: 600;
}

.text.xs {
  /* Elements/Button XXS */
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  /* 100% */
}

.text.tiny {
  /* Content/Tiny */
  font-size: 12px;
  line-height: 13px;
  /* 108.333% */
}

.text.tiny.bold {
  font-weight: 500;
}

.text.tiny.bolder {
  font-weight: 700;
}

.text b {
  font-weight: 600;
}

.text.bold.extra {
  font-weight: 700;
}

.text.small {
  font-size: 14px;
  line-height: 150%;
  /* 21px */
}

.text.small.bold {
  font-weight: 500;
}

.text.small.bold.strong {
  font-weight: 600;
}

.text.small.bold.extra {
  font-weight: 700;
}

.text.input-element {
  /* Elements/Input M */
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.caption.small {
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: var(--Colors-Text-text-primary, #292929);
}

.underline {
  text-decoration: underline;
}

.caption.medium {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  color: var(--Colors-Text-text-primary, #292929);
}

.primary {
  color: var(--Colors-Text-text-primary, #292929);
}

.secondary {
  color: var(--Colors-Text-text-secondary, #797466);
}

.palest {
  color: var(--Colors-Text-text-palest, #9e9a86);
}

.faded {
  color: var(--Colors-Text-text-faded, #918e7e);
}

.neutral {
  color: var(--Colors-Text-text-faded, #918e7e);
}

.red {
  color: var(--Colors-Functional-Danger-400, #d12d52);
}

.blue {
  color: var(--Colors-Functional-Info-700, #0e7490);
}

.blue-light {
  color: var(--Colors-Functional-Info-600, #22b5d1);
}

.blue-bg {
  background: var(--Colors-Functional-Info-200, #eefcfe);
}

.blue-border {
  border: 1px solid var(--Colors-Functional-Info-300, #c6eef2);
}

.partial-underline {
  position: relative;
}

.CheckboxRoot {
  background-color: white;
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px var(--black-a7);
  border: solid #bebeb3 1px;
}

.CheckboxRoot:hover {
  background-color: var(--violet-3);
}

.CheckboxRoot:focus {
  box-shadow: 0 0 0 2px black;
}

.CheckboxIndicator {
  color: var(--violet-11);
}

.form-validation-message {
  color: red;
}

.btn {
  cursor: pointer;

  justify-content: center;
  align-items: center;
  padding: 11px 13px;

  align-self: stretch;
  border-radius: var(--br-6, 6px);

  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: var(--Components-Button-Primary-button-primary-text, #292929);

  text-decoration: none;

  border: 1px solid var(--Components-Button-Primary-button-primary-border, #f6be30);
  background: var(--Colors-Yellow-500, #f6be30);

  position: relative;

  transition: 0.3s all;
  transform: translateZ(0);
}

.btn.flex {
  display: flex;
}

.btn .text {
  text-align: center;
  flex: 1;
}

.btn:not(.dbl):hover {
  background-color: var(--Components-Button-Primary-button-primary-color-hover,
      #fecd20);
}

.btn:not(.dbl):active {
  box-shadow: 0px 0px 0px 3px #2563eb52;
}

.btn.xs {
  /* Elements/Button XS */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  /* 116.667% */
}

.btn.dbl {
  padding: 1px;
  font-size: 18px;
  font-weight: 500;
  border-radius: var(--br-12, 12px);
  border: none;
}

.btn.dbl.success {
  background: #15a457;
}

.btn.dbl.warning {
  background: #cd8701;
}

.btn.dbl.info {
  background: #1483a6;
}

.btn.dbl.secondary {
  background: var(--Colors-Neutral-gray-200, #dcdccc);
}

.btn .front {
  height: 18px;
  min-width: 81px;
  padding: 19px 24px;
  transition: 0.3s;
  transform: translateY(-4px);
  border-radius: var(--br-10, 10px);
  margin: -1px;
  background-color: #fabb00;

  font-family: "Pally Variable";

  display: flex;
  align-items: center;
  justify-content: center;
}

.btn .front.loading {
  gap: 5px;
}

.btn .loading-container {
  width: 34px;
  height: 34px;
}

.btn .front.loading .loading-container .loader {
  border: solid 2px #000000;
  border-color: #000000 #000000 #000000 transparent;
}

.btn.success .front {
  background: var(--Colors-Functional-Success-600, #64eca2);
}

.btn.info .front {
  background: var(--Colors-Cyan-400, #30c0e0);
}

.btn.secondary .front {
  background: var(--Colors-Neutral-gray-50, #f7f7f3);
}

.btn:disabled .front {
  transform: translateY(0px);
}

.btn:not(:disabled):hover .front {
  transform: translateY(-3px);
}

.btn:not(:disabled):active .front {
  transform: translateY(0);
}

.btn:disabled {
  cursor: not-allowed;
}

.btn:not(.disable-disabled-opacity):disabled {
  opacity: 0.2;
}

.btn.medium {
  padding: 12px 14px;
}

.btn.normal {
  padding: 9px 11px;
  font-size: 16px;
}

.btn.small {
  display: flex;
  padding: 8px var(--spacing--12, 12px);
  justify-content: center;
  align-items: center;
  gap: 6px;
  height: 14px;

  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

.btn.large {
  padding-top: 15px;
  padding-bottom: 15px;
}

.btn.secondary {
  color: var(--Components-Button-Secondary-button-secondary-text, #797466);
  background-color: var(--Components-Button-Secondary-button-secondary-color,
      #f7f7f3);
  border-color: var(--Components-Button-Secondary-button-secondary-color,
      #f7f7f3);
}

.btn.secondary:hover {
  background-color: var(--Components-Button-Secondary-button-secondary-color-hover,
      #edeee6);
  color: var(--Components-Button-Secondary-button-secondary-text-hover,
      #292929);
}

.btn.transparent-bg,
.btn.transparent-bg:hover {
  background: transparent;
  border-color: transparent;
}

.btn.success {
  background: var(--Colors-Functional-Success-600, #64eca2);
  border-color: var(--Colors-Functional-Success-600, #64eca2);
  color: var(--Colors-Text-text-primary, #363636);
}

.btn.info {
  background: var(--Colors-Cyan-400, #30c0e0);
  border-color: var(--Colors-Cyan-400, #30c0e0);
  color: var(--Colors-Text-text-primary, #363636);
}

.btn.outline {
  border-color: var(--Components-Button-Outline-button-outline-color, #dcdccc);
  background: var(--Colors-Main-transparent, rgba(255, 255, 255, 0.01));
}

.btn.danger {
  color: var(--Components-Badge-badge-danger-text, #b02044);
  background: var(--Components-Badge-badge-danger-color, #fde6e8);
}

.btn.danger:hover {
  background-color: var(--Colors-Red-200, #fad1d6);
}

.btn.destructive {
  background-color: transparent;
  border-color: var(--Components-Button-Danger-button-destr-color, #d12d52);
  color: var(--Components-Button-Danger-button-destr-text, #d12d52);
}

.btn.destructive:hover {
  background-color: var(--Components-Button-Danger-button-destr-color-hover,
      #e11d48);
  border-color: var(--Components-Button-Danger-button-destr-color, #e11d48);
  color: #fff;
}

.btn.destructive:active {
  background-color: var(--Components-Button-Danger-button-destr-color-hover,
      #e11d48);
  border-color: #fff;
  color: #fff;
}

.btn.ghost {
  border-color: var(--Components-Button-Outline-button-outline-color, #c6c5b2);
  background-color: transparent;
  color: var(--Components-Button-Outline-button-outline-text-hover, #646059);
}

.btn.ghost:hover {
  border-color: var(--Components-Button-Outline-button-outline-color-hover,
      #9e9a86);
  background-color: transparent;
  color: var(--Components-Button-Outline-button-outline-text, #292929);
}

.btn.ghost:active {
  border-color: #fff;
  background-color: transparent;
  color: var(--Components-Button-Outline-button-outline-text, #292929);
}

.btn.black {
  color: white;
  background-color: #292929;
  border: 1px solid #292929;
}

.btn.black:active {
  background-color: #000;
}

.btn.black:hover {
  background-color: #333;
}

.btn.link {
  background: transparent;
  border: 1.5px solid transparent;
  border-color: transparent;
  color: var(--Components-Button-Link-button-link-text, #1a73e8);
  margin: 3px 3px 3px 0;
}

.btn.link:hover {
  background: transparent;
}

.btn.link:active {
  border: 1.5px solid var(--Components-Button-Link-button-link-border-active, #646059);
  box-shadow: 0px 0px 0px 3px #2563eb52;
}

.main-container {
  display: flex;
  padding: var(--br-4xl, 24px) var(--br-24, 24px) var(--br-4xl, 24px) var(--br-24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--br-24, 24px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--br-xl, 12px);
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.main-container .details {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
}

.main-container .details.column {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  height: 100%;
}

.main-container .moment-content {
  padding-bottom: 0;
}

.main-container.row {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.big-icon {
  display: flex;
  width: 56px;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: var(--br-md, 8px);
  background: var(--Colors-Functional-Info-300, #c6eef2);

  color: var(--Colors-Functional-Info-600, #22b5d1);
  /* font-family: "Pally Variable"; */
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 42px */
}

.input-big input {
  border-radius: var(--br-12, 12px);
  border: 2px solid var(--Colors-Neutral-gray-300, #dcdccc);
  background: var(--Colors-Functional-Mono-white, #fff);
  text-align: center;
  height: 72px;

  color: var(--Colors-Text-text-primary, #292929);

  /* Headline/H2 */
  font-family: "Pally Variable";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 24.2px */
}

.flex-spacer {
  flex: 1;
}

textarea {
  resize: none;
  padding: 16px;
  border-radius: var(--br-8, 8px);
  border: 1.5px solid var(--Colors-Neutral-gray-300, #dcdccc);
  background: var(--Colors-Functional-Mono-white, #fff);
  height: 168px;
  width: calc(100% - 32px);
  font-family: "Inter";
}

textarea:disabled {
  background: rgba(235, 234, 223, 0.5);
}

/* reset */
button,
fieldset,
input {
  all: unset;
}

.DialogOverlay {
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 450px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

  border-radius: var(--br-16, 16px);
  background: var(--Colors-Functional-Mono-white, #fff);
  box-shadow: 0px 8px 8px -4px rgba(17, 17, 20, 0.03);

  overflow: auto;
}

.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  margin-bottom: 30px;
  font-size: 22px;
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}

.IconButton {
  font-family: inherit;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--Colors-Neutral-gray-700, #444340);
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.IconButton:hover {
  background-color: var(--Colors-Neutral-gray-700, #444340);
  color: var(--Colors-Functional-Mono-white, #fff);
}

.IconButton:focus {
  box-shadow: 0 0 0 2px var(--Colors-Neutral-gray-700, #444340);
}

.Fieldset {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 15px;
}

.Label {
  font-size: 15px;
  color: violet;
  width: 90px;
  text-align: right;
}

.Input {
  width: 100%;
  flex: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 15px;
  line-height: 1;
  color: violet;
  box-shadow: 0 0 0 1px darkviolet;
  height: 35px;
}

.Input:focus {
  box-shadow: 0 0 0 2px darkorchid;
}

.input.small {
  width: calc(397px - 32px - 2px);
  padding: 10px 16px;
  font-size: 14px;
  color: var(--Colors-Neutral-gray-500, #9e9a86);
  border: 1px solid var(--Colors-Neutral-gray-300, #c6c5b2);
  border-radius: var(--br-10, 10px);
}

.FormContainer {
  display: flex;
  padding: 32px;
  width: 380px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing--8, 8px);
  border-radius: 10px;
}

.Form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing--16, 16px);
  width: 100%;
}

.FormFieldsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.FormField {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.FormControl {
  color: var(--Colors-Text-text-primary, #292929);
  width: calc(100% - 32px);
  padding: 6px 16px;
  border-radius: var(--br-12, 12px);
  border: 1px solid var(--Colors-Neutral-gray-300, #c6c5b2);
  background: var(--Colors-Functional-Mono-white, #fff);
  height: calc(54px - 12px);
  font-size: 15px;
}

.FormControl[data-invalid] {
  border: 1px solid var(--Colors-Text-text-primary, #941d3f);
}

.FormField[data-invalid] .FormMessage {
  color: #941d3f;
}

.SubmitButton {
  justify-content: center;
  align-items: center;
  padding: 14px 20px;

  align-self: stretch;
  border-radius: 12px;
  background: var(--Colors-Functional-Success-600, #64eca2);
  border: transparent;

  color: var(--Colors-Text-text-primary, #363636);
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.SubmitButton:disabled {
  cursor: not-allowed;
  opacity: 0.2;
}

.submit-hidden {
  display: none;
  height: 0;
}

.badge {
  /* text/tiny */
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
  height: 14px;
  /* 108.333% */

  text-align: center;

  color: var(--Colors-Amber-800, #92400e);
  padding: 2px 8px;
  /* border-radius: var(--br-pill, 9999px); */
  border-radius: var(--br-6, 6px);
  border: 1px solid var(--Colors-Amber-200, #fde68a);
  background: var(--Colors-Amber-50, #fffbeb);
}

.badge.green {
  border-color: var(--Colors-Green-200, #baf7d0);
  background: var(--Colors-Green-50, #f0fdf4);
  color: var(--Colors-Green-800, #166534);
}

.badge.blue {
  border-color: var(--Components-Badge-badge-info-border, #7cddf0);
  background: var(--Components-Badge-badge-info-color, #d2f5fb);
  color: var(--Components-Badge-badge-info-text, #1c566e);
}

.badge.secondary {
  color: var(--Components-Badge-badge-light-text, #646059);
  border: 1px solid var(--Components-Badge-badge-light-border, #dcdccc);
  background: var(--Components-Badge-badge-light-color, #f7f7f3);
}

.requiredAsterisk {
  color: var(--Colors-Functional-Danger-700, #b91c1c);
  font-size: 14px;
}

iframe {
  border-style: hidden;
}

.cursor-not-allowed {
  cursor: url("/public/icons/cursor-not-allowed.svg"), auto;
}

.no-interaction-section {
  pointer-events: none;
  user-select: none;
}

.textarea-sync-disabled,
.input-sync-disabled {
  padding: 16px;

  border-radius: var(--br-8, 8px);
  border: 1.5px solid var(--Colors-Text-text-palest, #dcdccc);
  background: rgba(235, 234, 223, 0.5);
  outline: none;
}

.textarea-sync-disabled {
  line-height: 21px;
  white-space: pre-wrap;
  width: 100%;
}

.textarea-sync-disabled.placeholder,
.input-sync-disabled.placeholder {
  color: var(--Colors-Neutral-gray-500, #9e9a86) !important;
}

/* keyframes */
@keyframes overlayShow {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}