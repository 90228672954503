.container {
  display: block;
}

.container p,
.container ul {
  margin-top: 8px;
}

.container p:first-child {
  margin-top: 0;
}

.container ul:first-child {
  margin-top: 0;
}