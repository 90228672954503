.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
  height: 100%;
  overflow: auto;
}

.scheduleFilters {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: var(--spacing--xxs, 2px);
  border-radius: 10px;
  background: var(--Colors-Neutral-gray-200, #edeee6);
  width: 184px;
}

.scheduleFilters>a {
  width: 100%;
  text-decoration: none;
  display: flex;
  height: 32px;
  padding: var(--spacing--sm, 0px) 14px;
  justify-content: center;
  align-items: center;
  gap: var(--spacing--sm, 8px);
  border-radius: 6px;
  cursor: pointer;
}

.scheduleFilters a:global(.active) {
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.scheduleContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.loaderContainer {
  height: 60vh;
}

.loaderContainer div:global(.loader) {
  width: 50px;
  height: 50px;
}

.scheduleContainer div:global(.no-data) {
  margin: 30px auto;
}

.scheduleHeader {
  display: flex;
  border-top: 1px solid var(--Colors-Neutral-gray-200, #dcdccc);
  border-bottom: 2px solid var(--Colors-Neutral-gray-200, #dcdccc);
}

.scheduleHeader .lineSection {
  display: flex;
  padding: 11px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.scheduleHeader .lineSection span:global(.text-subtitle.secondary) {
  color: var(--Colors-Text-text-palest, #9e9a86);
  font-feature-settings: "clig"off, "liga"off;
}

.scheduleHeader .date:hover {
  cursor: pointer;
}

.scheduleHeader .date i:global(.sort-icon) {
  width: 16px;
  height: 16px;
  color: var(--Colors-Text-text-palest, #9e9a86);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  line-height: 16px;
}

.scheduleHeader.slotInvitations {
  display: flex;
  width: 148px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
}

.scheduleHeader.details {
  display: flex;
  width: 180px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
}

.scheduleHeader.actionColumn {
  flex: 0 0 140px;
  display: flex;
  justify-content: flex-end;
}

.scheduleHeader.collapseControlColumn {
  flex: 0 0 32px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 100%;
}

.slotsBody {
  overflow: auto;
}

.slot {
  padding: 7px;
}

.slot:not(:global(.collapsed)):hover {
  background: var(--Colors-Neutral-gray-50, #f7f7f3);
}

.slot+.slot {
  border-top: 1px solid var(--Colors-Neutral-gray-200, #dcdccc);
}

.slotContent {
  display: flex;
  flex-direction: column;
  padding: 2px 8px;
  gap: 24px;
}

.slotLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 2px;
  gap: 20px;
}

.slot:global(.collapsed) {
  padding: 8px 0;
}

.slot:global(.collapsed):last-child {
  padding: 8px 0 0 0;
}

.slot:global(.collapsed) .slotLine {
  border-bottom: 1px solid var(--Colors-Neutral-gray-300, #dcdccc);
  padding: 8px 0px 16px 0px;
}

.slot:global(.collapsed) .slotContent {
  padding: 2px 16px;
  border-radius: var(--br-12, 12px);
  background: var(--Colors-Neutral-gray-100, #f7f7f3);
}

.slot:global(.collapsed) .lineSection.collapseControlColumn {
  rotate: 180deg;
}

.slot:global(.collapsed) .workshopName div:global(.text),
.slot:global(.collapsed) .date {
  font-weight: 600;
}

.slot:global(.collapsed) .actionColumn a:global(.secondary) {
  color: var(--Components-Button-Secondary-button-secondary-text_hover,
      #363636);
}

.slot:hover .actionColumn a:global(.secondary), .slot:global(.collapsed) .actionColumn a:global(.secondary) {
  border-color: var(--Colors-Neutral-gray-200, #dcdccc);

}

.lineSection {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 20px;
}

.lineSection div {
  display: flex;
  align-items: center;
  gap: 6px;
}

.lineSection.actionColumn {
  flex: 0 0 140px;
  display: flex;
  justify-content: flex-end;
}

.lineSection.actionColumn a {
  padding: 9px 12px;
  margin-top: 6px;
}

.lineSection.collapseControlColumn {
  flex: 0 0 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 100%;
}

.lineSection.details {
  gap: 8px;
}

.slotContent .collapseControlColumn:hover {
  background-color: rgba(145, 142, 126, 0.2);
  cursor: pointer;
}

.slotInvitations {
  gap: 6px;
}

.slotInvitations div:global(.invitations) {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 250px;
}

.slotInvitations div:global(.invitations-badge) {
  display: flex;
  padding: 3px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: var(--br-6, 6px);
  border: 1px solid var(--Components-Badge-badge-dark-border, #646059);
  background: var(--Components-Badge-badge-dark-color, #646059);
}

.slotInvitations div:global(.invitations-badge) span {
  color: var(--Components-Badge-badge-dark-text, #edeee6);
  text-align: center;
}

.slotInvitations .iconStatus {
  width: 14px;
  height: 14px;
  flex-shrink: 0;
}

.slotInvitations .iconStatus i {
  font-size: 8px;
}

.details div:global(.type),
.details div:global(.status) {
  display: flex;
  padding: 5px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
}

.details div:global(.type) {
  border-radius: var(--br-8, 8px);
  border: 1px solid var(--Colors-Neutral-gray-200, #edeee6);
  background: var(--Colors-Neutral-gray-50, #fff);
}

.details div:global(.status) {
  text-transform: capitalize;
  border-radius: var(--br-8, 8px);
}

.details div:global(.status.scheduled) {
  border: 1px solid var(--Colors-Amber-200, #fde68a);
  background: var(--Colors-Amber-50, #fffbeb);
  color: var(--Components-Badge-badge-primary-text, #874b0c);
}

.details div:global(.status.completed) {
  border: 1px solid var(--Colors-Green-200, #baf7d0);
  background: var(--Colors-Green-50, #f0fdf4);
  color: var(--Components-Badge-badge-success-text, #15663c);
}

.details div:global(.status.cancelled),div:global(.status.not_enough_players)  {
  border: 1px solid var(--Colors-Rose-200, #fecdd3);
  background: var(--Colors-Rose-50, #fff1f2);
  color: var(--Components-Badge-badge-danger-text, #b02044);
}

.details div:global(.status.ongoing) {
  text-transform: none;
  border: 1px solid var(--Colors-Cyan-200, #a5f3fc);
  background: var(--Colors-Cyan-50, #ecfeff);
  color: var(--Components-Badge-badge-info-text, #1c566e);
}

.slotCollapsedData {
  display: flex;
  flex-direction: column;
}

.slotCollapsedData.withSessions {
  gap: 24px;
}

.slotCollapsedData div:global(.invitations) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.slotCollapsedData div:global(.actions) {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  width: 100%;
}

.slotCollapsedData div:global(.actions .action-buttons) {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.slotCollapsedData div:global(.actions .action-buttons .action-link) {
  text-decoration: none;
  color: var(--Colors-Text-text-primary, #292929);
}

.slotCollapsedData div:global(.teams) {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.noOngoingSessions {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 265px;
  width: 100%;
}

.workshopResultsSkeletons {
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
}