.container {
  margin-top: 40px;
}

.title {
  margin-bottom: 24px;
  text-align: left;
}

.authorInfo {
  display: flex;
  gap: 24px;
  text-align: left;
  line-height: 24px;
  font-size: 18px;
}

.bio {
  margin-top: 24px;
}